import React from 'react';
import { Row, Col } from 'antd';

import styles from '~/domain/contract/components/EditForm/EditFormLayout/EditFormLayout.module.scss';

interface IEditFormLayoutProps {
  onSubmit: () => void;
  children: [React.ReactNode, React.ReactNode, React.ReactNode];
}

export const EditFormLayout: React.FC<IEditFormLayoutProps> = ({
  onSubmit,
  children,
}) => {
  return (
    <form onSubmit={onSubmit}>
      <Row gutter={32}>
        <Col span={12}>{children[0]}</Col>
        <Col span={12}>{children[1]}</Col>
        <Col span={12} className={styles.col}>
          {children[2]}
        </Col>
      </Row>
    </form>
  );
};
