import { type TInvoiceStatus } from '../types/invoiceStatuses';

import { i18trans } from '~/translation/i18n';

type TStatusTag = Record<TInvoiceStatus, { title: string; color: string }>;

export const invoiceStatusTags: TStatusTag = {
  new: {
    title: i18trans.t('invoiceStatuses.new', { ns: 'invoice' }),
    color: '#01AFF1',
  },
  paid: {
    title: i18trans.t('invoiceStatuses.paid', { ns: 'invoice' }),
    color: '#54B878',
  },
  overdue: {
    title: i18trans.t('invoiceStatuses.overdue', { ns: 'invoice' }),
    color: '#F1A041',
  },
  canceled: {
    title: i18trans.t('invoiceStatuses.canceled', { ns: 'invoice' }),
    color: '#B72136',
  },
  approved: {
    title: i18trans.t('invoiceStatuses.approved', { ns: 'invoice' }),
    color: '#54B878',
  },
  skipped: {
    title: i18trans.t('invoiceStatuses.skipped', { ns: 'invoice' }),
    color: '#B72136',
  },
};
