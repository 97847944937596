import { useQuery } from '@tanstack/react-query';

import { getWhoAmI } from '~/domain/user/api/whoAmI';
import { QUERY_KEYS } from '~/domain/shared/utils/queryKeys';

export const useWhoAmI = () => {
  const { data, isLoading, isError } = useQuery({
    queryKey: [QUERY_KEYS.USER.WHOAMI],
    retry: 0,
    queryFn: getWhoAmI,
  });

  return {
    data,
    isLoading,
    isError,
  };
};
