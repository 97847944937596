import React from 'react';
import { DatePicker as AntDatePicker } from 'antd';
import {
  Controller,
  type Control,
  type FieldPath,
  type FieldValues,
} from 'react-hook-form';
import dayjs from 'dayjs';

type TDatePicker<T extends FieldValues> = {
  name: FieldPath<T>;
  control: Control<T>;
  disabled?: boolean;
  placeholder?: string;
};

export const DatePicker = <T extends FieldValues>({
  name,
  control,
  disabled,
  placeholder,
}: TDatePicker<T>) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { value, onChange }, fieldState }) => (
        <AntDatePicker
          size="large"
          style={{ width: '100%' }}
          status={fieldState.error ? 'error' : ''}
          disabled={disabled}
          placeholder={placeholder}
          value={value && dayjs(value, 'YYYY-MM-DD')}
          onChange={(date) => onChange(date && date.format('YYYY-MM-DD'))}
        />
      )}
    />
  );
};
