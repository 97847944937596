import { InvoiceFilters } from '~/domain/invoices/components/InvoiceFilters';
import { InvoiceList } from '~/domain/invoices/components/InvoiceList';

export const InvoiceListPage: React.FC = () => {
  return (
    <>
      <div>
        <InvoiceFilters />
        <InvoiceList />
      </div>
    </>
  );
};
