import { Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { InvoiceDetails } from '~/domain/invoices/components/InvoiceDetails';
import { useInvoiceDetails } from '~/domain/invoices/hooks/useInvoiceDetails';
import { ErrorMessage } from '~/domain/shared/components/ErrorMessage';

export const InvoiceDetailsPage: React.FC = () => {
  const { id } = useParams();
  const { data, isError, isSuccess } = useInvoiceDetails(id);
  const { t } = useTranslation('common');

  if (isError) return <ErrorMessage subTitle={t('sorrySomethingWrong')} />;
  if (isSuccess) return <InvoiceDetails data={data} />;
  return <Spin />;
};
