import { contractCountries } from '~/config/contractCountries';

export const findWorkHours = (
  employmentType: string,
  currentValue: number,
  countryCode: string
) => {
  if (employmentType === 'specific') return currentValue;
  return (
    contractCountries.find((country) => {
      return country.code === countryCode;
    })?.EORContractFields.hoursPerWeek || 40
  );
};
