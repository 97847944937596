export const PATH = {
  LOG_IN: '/login',
  EOR_CONTRACT: '/eor-contract',
  EOR_CONTRACT_DETAIL: '/eor-contract/:id',
  EOR_CONTRACT_DETAIL_EDIT: '/eor-contract/:id/edit',
  EOR_CONTRACT_DETAIL_UPDATE: '/eor-contract/:id/update',

  FIXED_RATE_CONTRACT: '/fixed-rate-contract',
  FIXED_RATE_CONTRACT_DETAIL: '/fixed-rate-contract/:id',
  FIXED_RATE_CONTRACT_VIEW: '/fixed-rate-contract/:id/view',

  PAY_GO_CONTRACT: '/pay-go-contract',
  PAY_GO_CONTRACT_DETAIL: '/pay-go-contract/:id',
  PAY_GO_CONTRACT_VIEW: '/pay-go-contract/:id/view',

  ORGANIZATION: '/organization',
  ORGANIZATION_CREATE: 'create-organization',
  ORGANIZATION_EDIT: '/organization/:orgId/edit',
  ORGANIZATION_MEMBER: '/organization/:orgId/organization-member',
  ORGANIZATION_ADD_MEMBER: 'add',
  ORGANIZATION_EDIT_MEMBER: 'edit/:userId',
  ORGANIZATION_MEMBERS: 'organization-members',
  ORGANIZATION_MEMBERS_LIST: '/organization/:orgId/organization-members',

  KYC_LIST: '/kyc',
  KYC_DETAILS: '/kyc/:id',
  KYC_COMPLIANCE_SET: 'compliance/set',
  INVOICE: '/invoice',
  INVOICE_DETAIL: '/invoice/:id',
  INVOICE_DETAIL_EDIT: '/invoice/:id/edit',
  RECEIPT: '/receipt',
  RECEIPT_DETAIL: '/receipt/:id',
  RECEIPT_PAYROLL: 'payroll/:id',
  RECEIPT_EDIT: 'edit/:id',
  EMPLOYEE: '/employee',
  EMPLOYEE_DETAIL: '/employee/:id',
  EMPLOYER: '/employer',
  EMPLOYER_DETAIL: '/employer/:id',
  TEMP_CREATE_NEXT_INVOICE: '/create-next-invoice',
  RECRUITMENT_LIST: '/recruitment',
  RECRUITMENT_DETAILS: '/recruitment/:id',
  TALENT_POOL: '/talentpool',
  TALENT_POOL_DETAILS: '/talentpool/:id',
  TALENT_POOL_ADD: 'add',
  PRICING_MANAGEMENT: '/pricing-management',
  VISA_SERVICE: '/visaService',
  VISA_SERVICE_DETAILS: '/visaService/:id',
};
