import React from 'react';
import { Result } from 'antd';

interface IProps {
  title?: string;
  subTitle?: string;
}

export const ErrorMessage: React.FC<IProps> = ({ title, subTitle }) => {
  return (
    <Result status="error" title={title ?? ''} subTitle={subTitle ?? ''} />
  );
};
