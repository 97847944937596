import { z } from 'zod';

import { invoiceTypeArr } from '~/domain/invoices/types/invoiceType';
import { invoiceStatuses } from '~/domain/invoices/types/invoiceStatuses';

const invoiceListItemSchema = z.object({
  id: z.number(),
  number: z.string(),
  title: z.string(),
  contractName: z.string(),
  generationTime: z.string(),
  status: z.enum(invoiceStatuses),
  type: z.enum(invoiceTypeArr),
});

export const invoiceListSchema = z.object({
  totalRows: z.number(),
  totalPages: z.number(),
  data: z.array(invoiceListItemSchema),
});

export type TInvoiceList = z.TypeOf<typeof invoiceListSchema>;

export const prepareInvoiceListModel = (data: unknown) => {
  return invoiceListSchema.parse(data);
};
