import React from 'react';
import { Layout as AntdLayout } from 'antd';
import { Outlet as RouterOutlet } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { SideNav } from '~/Layout/SideNav';
import { HeaderAvatar } from '~/Layout/HeaderAvatar';

const { Header, Content, Footer } = AntdLayout;

export const Layout: React.FC = () => {
  const { t } = useTranslation(['common']);

  const env = import.meta.env.VITE_APP_ENVIROMENT;

  return (
    <AntdLayout>
      <SideNav />
      <AntdLayout>
        <Header className="p-0 flex flex-row-reverse background-white">
          <HeaderAvatar />
          <div className="mr-24 text-red-700 text-5xl">
            {env === 'production' ? null : env}
          </div>
        </Header>
        <div className="h-[calc(100vh-64px)] overflow-auto">
          <Content className="mx-6 my-4 ">
            <div className="p-6 bg-white">
              <RouterOutlet />
            </div>
          </Content>
          <Footer className="text-center">{t('workParallelFooter')}</Footer>
        </div>
      </AntdLayout>
    </AntdLayout>
  );
};
