import { contractCountries } from '~/config/contractCountries';

export const findPaidVacationDays = (
  timeOffType: string,
  currentValue: number,
  countryCode: string
) => {
  if (timeOffType === 'specific') return currentValue;
  const country = contractCountries.find(
    (country) => country.code === countryCode
  );
  const paidVacationDays = country?.EORContractFields?.timeOff || 0;
  return paidVacationDays;
};
