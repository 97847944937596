import { z } from 'zod';

import {
  IPricingListDTO,
  TContractType,
} from '~/domain/pricing/api/dto/pricingListDTO';

export const pricingListItemSchema = z.union([
  z.object({
    pricingType: z.literal('profit'),
    contractType: z.custom<TContractType>(),
    fiatValue: z.string(),
  }),
  z.object({
    pricingType: z.literal('fee'),
    contractType: z.custom<TContractType>(),
    countryCode: z.string(),
    countryName: z.string(),
    fiatValue: z.string(),
    fiatCurrency: z.string(),
    cryptoValue: z.string(),
    cryptoCurrency: z.string(),
  }),
  z.object({
    pricingType: z.literal('vat'),
    contractType: z.custom<TContractType>(),
    countryCode: z.string(),
    countryName: z.string(),
    fiatValue: z.string(),
  }),
]);

export const pricingListSchema = z.object({
  totalRows: z.number(),
  totalPages: z.number(),
  data: z.array(pricingListItemSchema),
});

export type TPricingList = z.TypeOf<typeof pricingListSchema>;

export const createPricingListModel = (data: IPricingListDTO) => {
  return pricingListSchema.parse(data);
};
