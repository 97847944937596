import axios from 'axios';

import { IPricingListDTO } from '~/domain/pricing/api/dto/pricingListDTO';
import { TContractFee } from '~/domain/pricing/api/model/contractFee';
import { createPricingListModel } from '~/domain/pricing/api/model/pricingListModel';
import type { TPricingList } from '~/domain/pricing/api/model/pricingListModel';
import { ENDPOINTS } from '~/domain/shared/utils/endpoints';

export const getPricingList = async (params) => {
  const { data } = await axios.get<IPricingListDTO>(ENDPOINTS.PRICING.LIST, {
    params,
  });

  return createPricingListModel(data);
};

export const saveFee = (data: TContractFee) => {
  return Promise.all([
    axios.put(ENDPOINTS.PRICING.LIST, {
      countryCode: data.countryCode,
      pricingType: 'vat',
      contractType: 'eor',
      fiatValue: data.eor.vat,
    }),
    axios.put(ENDPOINTS.PRICING.LIST, {
      countryCode: data.countryCode,
      pricingType: 'vat',
      contractType: 'fixed',
      fiatValue: data.fixed.vat,
    }),
    axios.put(ENDPOINTS.PRICING.LIST, {
      countryCode: data.countryCode,
      pricingType: 'vat',
      contractType: 'paygo',
      fiatValue: data.paygo.vat,
    }),
    axios.put(ENDPOINTS.PRICING.LIST, {
      countryCode: data.countryCode,
      pricingType: 'fee',
      contractType: 'eor',
      fiatValue: data.eor.fiat.value,
      fiatCurrency: data.eor.fiat.currency,
      cryptoValue: data.eor.crypto.value,
      cryptoCurrency: data.eor.crypto.currency,
    }),
    axios.put(ENDPOINTS.PRICING.LIST, {
      countryCode: data.countryCode,
      pricingType: 'fee',
      contractType: 'fixed',
      fiatValue: data.fixed.fiat.value,
      fiatCurrency: data.fixed.fiat.currency,
      cryptoValue: data.fixed.crypto.value,
      cryptoCurrency: data.fixed.crypto.currency,
    }),
    axios.put(ENDPOINTS.PRICING.LIST, {
      countryCode: data.countryCode,
      pricingType: 'fee',
      contractType: 'paygo',
      fiatValue: data.paygo.fiat.value,
      fiatCurrency: data.paygo.fiat.currency,
      cryptoValue: data.paygo.crypto.value,
      cryptoCurrency: data.paygo.crypto.currency,
    }),
  ]);
};

export const getEORFeeDetails = async (countryCode: string) => {
  const { data } = await axios.get<IPricingListDTO>(ENDPOINTS.PRICING.LIST, {
    params: {
      countryCode,
      contractType: 'eor',
      pricingType: 'fee',
    },
  });

  if (!data.data.length)
    throw new Error('No such country found (country code is invalid)');

  return data;
};

export const getProfitList = async () => {
  const { data } = await axios.get<IPricingListDTO>(ENDPOINTS.PRICING.LIST, {
    params: {
      page: 1,
      pageSize: 99999,
      pricingType: 'profit',
    },
  });

  return createPricingListModel(data);
};

export const saveProfit = (data: TPricingList['data'][number]) =>
  axios.put(ENDPOINTS.PRICING.LIST, {
    pricingType: 'profit',
    contractType: data.contractType,
    fiatValue: data.fiatValue,
  });
