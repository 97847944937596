import axios from 'axios';

import { timesheetsListModel } from '~/domain/contract/api/model/timesheetListModel';
import { type TPayGoDetailsDTO } from '~/domain/contract/api/dto/payGoDTO';
import { PayGoContractModel } from '~/domain/contract/api/model/payGoContractModel';
import { type IContractsListDTO } from '~/domain/contract/api/dto/contractsListDTO';
import { type IFRContractDTO } from '~/domain/contract/api/dto/FRContractDTO';
import { type IContractsSearchParams } from '~/domain/contract/types/contractsSearchParams';
import { contractsListModel } from '~/domain/contract/api/model/contractsListModel';
import { FRContractModel } from '~/domain/contract/api/model/FRContractModel';
import { ENDPOINTS } from '~/domain/shared/utils/endpoints';
import {
  type TEORContractDetailsModel,
  prepareEORContractDetailsModel,
} from '~/domain/contract/api/model/EORContractModel';

export const getContractsList = async (params: IContractsSearchParams) => {
  const { data } = await axios.get<IContractsListDTO>(
    ENDPOINTS.CONTRACTS.LIST,
    { params }
  );

  return contractsListModel(data);
};

export const getEORContractDetails = async (id: string | undefined) => {
  const { data } = await axios.get<unknown>(
    `${ENDPOINTS.CONTRACTS.LIST}/${id}`
  );

  return prepareEORContractDetailsModel(data);
};

export const getFRContractDetails = async (id: string | undefined) => {
  const { data } = await axios.get<IFRContractDTO>(
    `${ENDPOINTS.CONTRACTS.LIST}/${id}`
  );

  return FRContractModel(data);
};

export const getPayGoContractDetails = async (id: string | undefined) => {
  const { data } = await axios.get<TPayGoDetailsDTO>(
    `${ENDPOINTS.CONTRACTS.LIST}/${id}`
  );

  return PayGoContractModel(data);
};

export const depositPaid = async (id: number, voucher: File | null) => {
  return await axios.post(
    `${ENDPOINTS.CONTRACTS.LIST}/${id}/deposit-paid`,
    {
      voucher,
    },
    { headers: { 'Content-Type': 'multipart/form-data' } }
  );
};

type TOfflineMSA = {
  id: number;
  data: FileList;
};

export const offlineMSA = async ({ id, data }: TOfflineMSA) => {
  if (!data[0]) return;

  const formData = new FormData();
  formData.append('file', data[0]);

  return await axios.put<{ file: FileList }>(
    `/operators/contracts/${id}/msa`,
    formData
  );
};

export const sendContractToEmployee = async (id: number) => {
  const url = `operators/contracts/${id}/send`;
  const method = 'put';

  return await axios<TEORContractDetailsModel>({
    url,
    method,
  });
};

export const setEORContractCosts = async (data: TEORContractDetailsModel) => {
  const url = `/operators/contracts/${data.id}/costs`;
  const method = 'post';

  return await axios<TEORContractDetailsModel>({
    url,
    method,
    data,
  });
};

export const updateEORContractDetails = async (
  data: TEORContractDetailsModel
) => {
  const url = `operators/contracts/${data.id}/update`;
  const method = 'put';

  return await axios<TEORContractDetailsModel>({
    url,
    method,
    data: { ...data, costs: undefined },
  });
};

export const getTimesheetList = async (id: number) => {
  const { data } = await axios.get(
    `${ENDPOINTS.CONTRACTS.LIST}/${id}/timesheets`
  );

  return timesheetsListModel(data);
};
