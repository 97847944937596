import { forEach, groupBy, sumBy } from 'lodash';

type TCurrencyItem = {
  currency: string;
  value: string;
};

type TTotalItem = {
  currency: string;
  total: string;
};

export const calculateCurrencyTotals = (
  data: TCurrencyItem[]
): TTotalItem[] => {
  const groupedData = groupBy(data, 'currency');

  const totals: TTotalItem[] = [];

  forEach(groupedData, (group, currency) => {
    const totalValue = sumBy(group, (item) => {
      const value = Number(item.value);

      if (isNaN(value) && value < 0) return 0;
      return value;
    });
    if (currency)
      totals.push({
        currency,
        total: totalValue.toFixed(2),
      });
  });

  return totals;
};
