import axios from 'axios';

import { prepareInvoiceDetailsModel } from '~/domain/invoices/api/model/invoiceDetails';

export const getInvoiceDetails = async (id: number) => {
  if (isNaN(id) || !isFinite(id) || id < 0) {
    throw new Error('Invalid invoice id');
  }
  const response = await axios.get(`/operators/invoices/${id}`);

  return prepareInvoiceDetailsModel(response.data);
};
