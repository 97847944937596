import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';

import common from './en/common.json';
import user from './en/user.json';
import contract from './en/contract.json';
import notification from './en/notification.json';
import invoice from './en/invoice.json';
import receipt from './en/receipt.json';
import EORContract from './en/EORContract.json';
import recruitment from './en/recruitment.json';
import talentPool from './en/talentPool.json';
import pricing from './en/pricing.json';
import visaService from './en/visaService.json';
import responses from './en/responses.json';

declare module 'i18next' {
  interface CustomTypeOptions {
    returnNull: false;
  }
}

declare module 'i18next' {
  interface CustomTypeOptions {
    defaultNS: 'common';
    resources: {
      common: typeof common;
      user: typeof user;
      contract: typeof contract;
      notification: typeof notification;
      invoice: typeof invoice;
      receipt: typeof receipt;
      recruitment: typeof recruitment;
      EORContract: typeof EORContract;
      talentPool: typeof talentPool;
      pricing: typeof pricing;
      visaService: typeof visaService;
      responses: typeof responses;
    };
  }
}

i18next.use(initReactI18next).init({
  returnNull: false,
  debug: true,
  fallbackLng: 'en',
  resources: {
    en: {
      common,
      user,
      contract,
      notification,
      invoice,
      receipt,
      recruitment,
      EORContract,
      talentPool,
      pricing,
      visaService,
      responses,
    },
  },
});

export const i18trans = i18next;
