import React from 'react';

interface IInputWrapperProps {
  label?: string;
  hidden?: boolean;
}

export const InputWrapper: React.FC<
  IInputWrapperProps & React.PropsWithChildren
> = ({ label, hidden, children }) => {
  if (hidden) return null;
  return (
    <div style={{ margin: '0 0 6px 0', width: '100%' }}>
      {label && <p style={{ margin: 0, marginBottom: 6 }}>{label}</p>}
      {children}
    </div>
  );
};
