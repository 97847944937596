import { z } from 'zod';

export const timesheetsSchema = z.object({
  id: z.number(),
  periodStart: z.string(),
  periodEnd: z.string(),
  status: z.string(),
});

export const timesheetsListSchema = z.object({
  totalPages: z.number(),
  totalRows: z.number(),
  data: z.array(timesheetsSchema),
});

export type TTimesheetsListDTO = z.TypeOf<typeof timesheetsListSchema>;
