import React, { useState } from 'react';

interface UseUploadResult {
  file: File | null;
  filePreviewUrl: string | null;
  fileName: string | null | undefined;
  handleImageChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleClear: () => void;
}

export const useImageUpload = (): UseUploadResult => {
  const [file, setFile] = useState<File | null>(null);
  const [filePreviewUrl, setFilePreviewUrl] = useState<string | null>(null);
  const [fileName, setFileName] = useState<string | null | undefined>(null);

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();

    const reader = new FileReader();
    let fileUpload: File | null = null;

    if (event.target && event.target.files) {
      fileUpload = event.target.files[0] ?? null;
    }

    reader.onloadend = () => {
      setFile(fileUpload);
      setFilePreviewUrl(reader.result as string);
      setFileName(fileUpload?.name);
    };

    reader.readAsDataURL(fileUpload as Blob);
  };

  const handleClear = () => {
    setFile(null);
    setFilePreviewUrl(null);
    setFileName(null);
  };

  return { file, filePreviewUrl, fileName, handleImageChange, handleClear };
};
