import React, { PropsWithChildren } from 'react';
import {
  BrowserRouter,
  matchRoutes,
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
} from 'react-router-dom';
import {
  MutationCache,
  QueryCache,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import { isAxiosError } from 'axios';
import i18next from 'i18next';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { ConfigProvider, notification } from 'antd';
import { ZodError } from 'zod';
import enUS from 'antd/locale/en_US';
import * as Sentry from '@sentry/react';

import { version } from '../package.json';

const axiosError = (error: unknown) => {
  if (isAxiosError(error)) {
    const message = error.response?.data?.action || 'something_went_wrong';
    notification.error({
      duration: 5000,
      message: (
        <div>
          <div>Axios Error:</div>
          <div>{i18next.t(message, { ns: 'responses' })}</div>
        </div>
      ),
    });
  }
};

const zodError = (error: unknown) => {
  if (error instanceof ZodError) {
    error.issues.forEach((issue) => {
      notification.error({
        duration: 5000,
        message: (
          <div>
            <div>Zod Error:</div>
            <div>{issue.message}</div>
          </div>
        ),
        description: JSON.stringify(issue),
      });
    });
  }
};
const queryCache = new QueryCache({
  onError: (error) => {
    zodError(error);
    axiosError(error);
  },
});

const mutationCache = new MutationCache({
  onError: (error) => {
    zodError(error);
    axiosError(error);
  },
  onSuccess: (data, _cos, _aa, mutation) => {
    if (mutation.options.mutationKey?.[0] === 'login') {
      return;
    }
    notification.success({
      message: i18next.t('interaction_success', { ns: 'responses' }),
    });
  },
});

export const queryClient = new QueryClient({
  defaultOptions: { queries: { staleTime: 1000 * 10, gcTime: 1000 * 20 } },
  queryCache,
  mutationCache,
});

Sentry.init({
  dsn: 'https://e2d77651ee604d95b87e8f1bd73092f6@o1294427.ingest.sentry.io/4504645483560960',
  release: version,
  tracesSampleRate: 1.0,
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        React.useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      ),
    }),
  ],
  environment: import.meta.env.VITE_APP_ENVIROMENT,
  enabled: ['production', 'pre-production', 'staging'].includes(
    import.meta.env.VITE_APP_ENVIROMENT || ''
  ),
});
export const Providers: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools />
      <ConfigProvider locale={enUS}>
        <BrowserRouter>{children}</BrowserRouter>
      </ConfigProvider>
    </QueryClientProvider>
  );
};
