import React from 'react';
import { Select as AntSelect } from 'antd';
import {
  Controller,
  type Control,
  type FieldPath,
  type FieldValues,
} from 'react-hook-form';

type TSelect<T extends FieldValues> = {
  name: FieldPath<T>;
  control: Control<T>;
  disabled?: boolean;
  placeholder?: string;
  options: { value: string; label: string }[];
  customOnChange?: (value: string) => void;
};

export const Select = <T extends FieldValues>({
  name,
  control,
  disabled,
  placeholder,
  options,
  customOnChange,
}: TSelect<T>) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState }) => (
        <AntSelect
          size="large"
          style={{ width: '100%' }}
          options={options}
          status={fieldState.error ? 'error' : ''}
          disabled={disabled}
          placeholder={placeholder}
          {...field}
          onChange={(value) => {
            customOnChange && customOnChange(value);
            field.onChange(value);
          }}
        />
      )}
    />
  );
};
