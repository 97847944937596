import { Avatar } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import React, { useRef } from 'react';

interface UploadFileProps {
  type: 'image' | 'file';
  previewUrl: string | null | undefined;
  handleFileChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const UploadFile: React.FC<UploadFileProps> = ({
  type,
  previewUrl,
  handleFileChange,
}) => {
  const fileInput = useRef<HTMLInputElement>(null);

  const handleEditFile = () => {
    if (!fileInput.current) return;
    fileInput.current.click();
  };

  const renderContent = {
    image: (
      <div onClick={handleEditFile}>
        <Avatar src={previewUrl} size={64} icon={<PlusOutlined />} />
        <input
          accept="image/*"
          type="file"
          onChange={handleFileChange}
          style={{ display: 'none' }}
          ref={fileInput}
        />
      </div>
    ),

    file: (
      <div
        className="border-dotted border-2 border-sky-500 rounded p-4 text-center cursor-pointer bg-slate-300"
        onClick={handleEditFile}
      >
        <PlusOutlined />
        <input
          accept={
            'application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,text/plain, application/pdf, image/*'
          }
          type="file"
          style={{ display: 'none' }}
          onChange={handleFileChange}
          ref={fileInput}
        />
      </div>
    ),
  };

  return renderContent[type];
};
