import { cryptoCurrencies } from '~/config/currencies';

export const formatCurrency = (value?: string | number, currency?: string) => {
  const numValue = Number(value);

  if (!currency || isNaN(numValue)) return '-';

  try {
    if (cryptoCurrencies.some((el) => el.code === currency)) {
      return `${currency}${value}`;
    } else {
      return new Intl.NumberFormat('ja-JP', {
        style: 'currency',
        currency,
      }).format(numValue);
    }
  } catch {
    return '-';
  }
};
