import { useQuery } from '@tanstack/react-query';

import { getInvoiceDetails } from '~/domain/invoices/api/requests/getInvoiceDetails';
import { QUERY_KEYS } from '~/domain/shared/utils/queryKeys';

export const useInvoiceDetails = (id: string | undefined) => {
  const numberId = Number(id);

  return useQuery({
    queryKey: [QUERY_KEYS.INVOICES.INVOICE, 'new', id],
    queryFn: async () => await getInvoiceDetails(numberId),
  });
};
