import { Table, Pagination, Button, Tag } from 'antd';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import {
  INIT_FILTERS_INVOICES,
  useInvoiceList,
} from '~/domain/invoices/hooks/useInvoiceList';
import { invoiceStatusTags } from '~/domain/invoices/helpers/invoiceStatusTags';
import { invoiceTypesTags } from '~/domain/invoices/helpers/invoiceTypeTags';
import { useListFilter } from '~/domain/shared/hooks/useListFilter';
import { dateFormat } from '~/domain/shared/utils/dateFormat';

export const InvoiceList: React.FC = () => {
  const { t } = useTranslation(['invoice']);

  const { filterParams, handleSearchParams } = useListFilter(
    INIT_FILTERS_INVOICES
  );

  const { invoices, isLoading } = useInvoiceList();

  const columns = [
    {
      title: t('invoice:invoiceNo'),
      dataIndex: 'number',
      key: 'number',
      render: (value: string) => (
        <div style={{ whiteSpace: 'nowrap', overflow: 'hidden' }}>{value}</div>
      ),
    },
    {
      title: t('invoice:invoiceTitle'),
      dataIndex: 'title',
      key: 'title',
      render: (value: string) => (
        <div style={{ whiteSpace: 'nowrap', overflow: 'hidden' }}>{value}</div>
      ),
    },
    {
      title: t('invoice:contractName'),
      dataIndex: 'contractName',
      key: 'contractName',
      render: (value: string) => (
        <div style={{ whiteSpace: 'nowrap', overflow: 'hidden' }}>{value}</div>
      ),
    },
    {
      title: t('invoice:generationTime'),
      dataIndex: 'generationTime',
      key: 'generationTime',
      render: (value: string) => (
        <div style={{ whiteSpace: 'nowrap', overflow: 'hidden' }}>
          {dateFormat.dateTime(value)}
        </div>
      ),
    },
    {
      title: t('invoice:type'),
      dataIndex: 'type',
      key: 'type',
      render: (type: string) => (
        <Tag
          style={{
            textAlign: 'center',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
          }}
        >
          {invoiceTypesTags[type]?.title}
        </Tag>
      ),
    },
    {
      title: t('invoice:status'),
      dataIndex: 'status',
      key: 'status',
      render: (status: string) => (
        <Tag
          style={{
            textAlign: 'center',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            background: invoiceStatusTags[status]?.color,
            color: 'white',
          }}
        >
          {invoiceStatusTags[status]?.title}
        </Tag>
      ),
    },
    {
      title: t('invoice:actions'),
      dataIndex: 'id',
      key: 'id',
      render: (id: number) => (
        <Link to={`/invoice/${id}`}>
          <Button type="primary">{t('invoice:view')}</Button>
        </Link>
      ),
    },
  ];

  return (
    <div>
      <Table
        scroll={{ x: true, y: undefined }}
        rowKey={(record) => record.id}
        dataSource={invoices?.data ?? []}
        columns={columns}
        pagination={false}
        loading={isLoading}
      />
      <Pagination
        style={{ margin: '24px 0 0 0' }}
        current={filterParams.page}
        total={invoices?.totalRows}
        pageSize={filterParams.pageSize}
        showSizeChanger={false}
        onChange={(page) => handleSearchParams('page', page)}
      />
    </div>
  );
};
