import { contractCountries } from '~/config/contractCountries';

// contractCurrencies contains a list of currencies that users can select
// from when creating a new contact. The list includes currencies used in
// supported countries as well as three major global currencies: USD (United States Dollar)
// EUR (Euro), and GBP (British Pound). The constant is also used when creating new job listings.
export const contractCurrencies = [
  ...contractCountries.map((country) => country.currency),
  {
    code: 'GBP',
    name: 'Sterling',
    symbol: '£',
  },
  {
    code: 'EUR',
    name: 'Euro',
    symbol: '€',
  },
  {
    code: 'USD',
    name: 'American dollar',
    symbol: '$',
  },
];

// A list of traditional fiat currencies the employer can choose for invoice payments.
export const fiatPaymentCurrencies = contractCurrencies.filter((currency) =>
  ['AUD', 'USD', 'SGD', 'EUR', 'HKD', 'GBP'].includes(currency.code)
);

// A list of crypto currencies the employer can choose for invoice payments.
export const cryptoCurrencies = [
  {
    code: 'USDC',
    name: 'USD CIRCLE',
  },
  {
    code: 'USDT',
    name: 'Tether USDT',
  },
  {
    code: 'ETH',
    name: 'Ethereum',
  },
] as const;

export const allSupportedCurrencies = [
  ...new Set([...contractCurrencies, ...cryptoCurrencies]),
] as const;
