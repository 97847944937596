import React, { useEffect, useState } from 'react';
import { Input, InputProps } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

import { useDebounce } from '~/domain/shared/hooks/useDebounce';

type TInputProps = {
  onSearch?: (data: string) => void;
  placeholder: string;
  disabled?: boolean;
  initialValue?: string;
} & InputProps;

export const InputSearch = ({
  placeholder,
  onSearch,
  disabled,
  initialValue,
  className,
  ...rest
}: TInputProps) => {
  const [searchTerm, setSearchTerm] = useState<string>('');
  const debouncedSearchTerm: string = useDebounce(searchTerm, 500);

  useEffect(() => {
    if (initialValue) {
      setSearchTerm(initialValue);
    }

    return () => setSearchTerm('');
  }, [initialValue]);

  useEffect(() => {
    if (onSearch) {
      onSearch(debouncedSearchTerm);
    }
  }, [debouncedSearchTerm]);

  return (
    <Input
      className={`w-[200px] ${className}`}
      placeholder={placeholder}
      prefix={<SearchOutlined />}
      onChange={(e) => setSearchTerm(e.target.value)}
      allowClear
      disabled={disabled}
      value={searchTerm}
      {...rest}
    />
  );
};
