export const ENDPOINTS = {
  USER: {
    WHOAMI: '/user/whoami',
  },
  AUTH: {
    LOGIN: '/auth/login',
    LOGOUT: '/auth/logout',
  },
  EMPLOYEES: {
    DOCUMENTS: '/operators/employees/documents',
    EMPLOYEE: '/operators/employees',
  },
  EMPLOYER: {
    LIST: '/operators/employers',
  },
  INVOICES: {
    LIST: '/operators/invoices',
  },
  RECEIPTS: {
    LIST: '/operators/receipts',
  },
  ORGANIZATIONS: {
    LIST: '/operators/organizations',
  },
  COMPLIANCE: {
    LIST: '/operators/compliance',
  },
  CONTRACTS: {
    LIST: '/operators/contracts',
  },
  PRICING: {
    LIST: '/operators/pricings',
    CURRENCIES: '/operators/conversions/currencies',
  },
} as const;
