import { useTranslation } from 'react-i18next';
import {
  ArrowLeftOutlined,
  CheckOutlined,
  CloseOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';
import { useForm, useFieldArray, SubmitHandler } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { Space, Button, Popover, Card } from 'antd';
import { Link, useNavigate } from 'react-router-dom';

import {
  invoiceDetailsSchema,
  TInvoiceDetails,
} from '~/domain/invoices/api/model/invoiceDetails';
import { TextField } from '~/domain/shared/components/Forms/ControlledInputs/TextField';
import { InputWrapper } from '~/domain/shared/components/Forms/InputWrapper';
import { TextArea } from '~/domain/shared/components/Forms/ControlledInputs/TextArea';
import { useInvoiceApprove } from '~/domain/invoices/hooks/useInvoiceApprove';
import { Select } from '~/domain/shared/components/Forms/ControlledInputs/Select';
import { allSupportedCurrencies } from '~/config/currencies';

type TInvoiceDetailsEditProps = {
  data: TInvoiceDetails;
};

export const InvoiceDetailsEdit = ({ data }: TInvoiceDetailsEditProps) => {
  const { t } = useTranslation(['common', 'invoice']);
  const navigate = useNavigate();
  const { control, handleSubmit, register, getValues, setValue, watch } =
    useForm<TInvoiceDetails>({
      defaultValues: data,
      mode: 'onBlur',
      resolver: zodResolver(invoiceDetailsSchema),
    });

  const { fields, append, remove } = useFieldArray({
    control: control,
    name: 'data.positions',
  });

  const { mutate, isPending } = useInvoiceApprove(data.ID, () =>
    navigate(`/invoice/${data.ID}`)
  );

  const onSubmit: SubmitHandler<TInvoiceDetails> = (data) => {
    mutate(data);
  };

  const calc = (index: number, amount: string, vatRate: string) => {
    const amountValue = Number(amount);
    const vatRateValue = Number(vatRate);
    const result =
      vatRateValue === 0 ? amountValue : amountValue * (1 + vatRateValue / 100);

    setValue(`data.positions.${index}.grossValue`, String(result.toFixed(2)));
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Space style={{ width: '100%' }}>
        <Link to={`/invoice/${data.ID}`}>
          <Button type="primary" icon={<ArrowLeftOutlined />}>
            {t('invoice:abandonChange')}
          </Button>
        </Link>
        <Button
          type="primary"
          icon={<CheckOutlined />}
          onClick={handleSubmit(onSubmit)}
          loading={isPending}
        >
          {t('invoice:approve')}
        </Button>
        <Popover
          trigger="click"
          content={
            <p style={{ fontSize: 10, padding: 12 }}>{JSON.stringify(data)}</p>
          }
        >
          <Button type="default" icon={<InfoCircleOutlined />} />
        </Popover>
      </Space>
      <Space
        style={{ marginTop: 32, width: '100%' }}
        direction="vertical"
        size="large"
      >
        <Card title={t('invoice:logo')}>
          <input type="file" {...register('base64logo')} />
        </Card>
        <Card title={t('invoice:contractDetails')}>
          <InputWrapper label={t('invoice:issueDate')}>
            <TextField<TInvoiceDetails>
              control={control}
              name="data.issueDate"
            />
          </InputWrapper>
          <InputWrapper label={t('invoice:dueDate')}>
            <TextField<TInvoiceDetails> control={control} name="data.dueDate" />
          </InputWrapper>
          <InputWrapper label={t('invoice:totalDue')}>
            <TextField<TInvoiceDetails>
              control={control}
              name="data.totalDue"
            />
          </InputWrapper>
          <InputWrapper label={t('invoice:totalDueCurrency')}>
            <Select<TInvoiceDetails>
              options={allSupportedCurrencies.map((c) => ({
                value: c.code,
                label: `${c.code} - ${c.name}`,
              }))}
              control={control}
              name="currency"
            />
          </InputWrapper>
        </Card>
        <Card title={t('invoice:from')}>
          {data?.data.billFrom.map((item, index) => (
            <InputWrapper key={index}>
              <TextField<TInvoiceDetails>
                control={control}
                name={`data.billFrom.${index}`}
                placeholder={t('invoice:line', { line: index + 1 })}
              />
            </InputWrapper>
          ))}
        </Card>
        <Card title={t('invoice:to')}>
          {data?.data.billTo.map((_, index) => (
            <InputWrapper key={index}>
              <TextField<TInvoiceDetails>
                control={control}
                name={`data.billTo.${index}`}
                placeholder={t('invoice:line', { line: index + 1 })}
              />
            </InputWrapper>
          ))}
        </Card>
        <Card title={t('invoice:contractDetails')}>
          <InputWrapper label={t('invoice:name')}>
            <TextField<TInvoiceDetails>
              control={control}
              name="data.contract"
            />
          </InputWrapper>
          <InputWrapper label={t('invoice:scope')}>
            <TextArea<TInvoiceDetails> control={control} name="data.scope" />
          </InputWrapper>
        </Card>
        <Card
          title="Positions"
          extra={
            <Button
              onClick={() =>
                append({
                  name: '',
                  amount: '',
                  vatRate: '',
                  grossValue: '',
                  currency: getValues('currency'),
                })
              }
            >
              {t('invoice:addPosition')}
            </Button>
          }
        >
          {fields.map((item, index) => (
            <InputWrapper key={item.id}>
              <Space direction="horizontal">
                <TextField<TInvoiceDetails>
                  control={control}
                  name={`data.positions.${index}.name`}
                  placeholder={t('invoice:name')}
                />
                <TextField<TInvoiceDetails>
                  control={control}
                  name={`data.positions.${index}.amount`}
                  placeholder={t('invoice:amount')}
                  onCustomChange={(value) =>
                    calc(
                      index,
                      value,
                      getValues(`data.positions.${index}.vatRate`)
                    )
                  }
                />
                <TextField<TInvoiceDetails>
                  control={control}
                  name={`data.positions.${index}.vatRate`}
                  placeholder={t('invoice:vatRate')}
                  onCustomChange={(value) =>
                    calc(
                      index,
                      getValues(`data.positions.${index}.amount`),
                      value
                    )
                  }
                  addonAfter="%"
                />
                <TextField<TInvoiceDetails>
                  readOnly
                  control={control}
                  name={`data.positions.${index}.grossValue`}
                  placeholder={t('invoice:grossValue')}
                />
                <Select
                  options={allSupportedCurrencies.map((c) => ({
                    value: c.code,
                    label: `${c.code} - ${c.name}`,
                  }))}
                  control={control}
                  name={`data.positions.${index}.currency`}
                />
                <Button
                  danger
                  type="primary"
                  disabled={watch('data.positions').length <= 1}
                  onClick={() => remove(index)}
                >
                  <CloseOutlined />
                </Button>
              </Space>
            </InputWrapper>
          ))}
        </Card>
      </Space>
    </form>
  );
};
