import { UploadFile } from 'antd';
import { RcFile } from 'antd/es/upload';
import axios from 'axios';

import { ENDPOINTS } from '~/domain/shared/utils/endpoints';

type TUploadFileRequest = {
  url: string;
  formKey: string;
  method: string;
  file: UploadFile | undefined;
};

export const uploadFileRequest = async ({
  url,
  formKey,
  method,
  file,
}: TUploadFileRequest) => {
  if (!file) return;
  const data = new FormData();
  data.append(formKey, file as RcFile);

  return await axios({
    url,
    data,
    method,
  });
};

export interface TExchangeCurrenciesArgs {
  from: string;
  to: string;
  amount: string;
}

export const exchangeCurrencies = async ({
  from,
  to,
  amount,
}: TExchangeCurrenciesArgs) => {
  const response = await axios.get<number>(
    `${ENDPOINTS.PRICING.CURRENCIES}/${from}/${to}/${amount}`
  );

  if (response.data === 0) {
    throw new Error('Invalid currency conversion: Response data is 0');
  }

  return response.data;
};

export const exchangeMultipleCurrencies = (
  from: {
    total: string;
    currency: string;
  }[],
  to: string
) =>
  Promise.all(
    from.map(({ currency, total }) =>
      exchangeCurrencies({ from: currency, to, amount: total })
    )
  );
