import { UploadOutlined } from '@ant-design/icons';
import { useQueryClient } from '@tanstack/react-query';
import { Button, Modal } from 'antd';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

import { UploadFile } from '~/domain/shared/components/UploadFile';
import { useImageUpload } from '~/domain/shared/hooks/useUploadFile';
import { QUERY_KEYS } from '~/domain/shared/utils/queryKeys';

type TInvoiceVoucherModalProps = {
  id: number;
  isModalOpen: boolean;
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export const InvoiceVoucherModal = ({
  id,
  isModalOpen,
  setIsModalOpen,
}: TInvoiceVoucherModalProps) => {
  const { t } = useTranslation(['invoice']);
  const { file, filePreviewUrl, handleImageChange } = useImageUpload();
  const queryClient = useQueryClient();
  const upload = async () => {
    axios
      .post(
        `/operators/invoices/${id}/employer/sent`,
        { voucher: file },
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
      )
      .then(() => {
        queryClient.resetQueries({
          queryKey: [QUERY_KEYS.INVOICES.INVOICE, 'new', id],
        });
        setIsModalOpen(false);
      });
  };

  return (
    <Modal
      open={isModalOpen}
      onOk={() => setIsModalOpen(false)}
      onCancel={() => setIsModalOpen(false)}
      footer={null}
    >
      <UploadFile
        previewUrl={filePreviewUrl}
        type="file"
        handleFileChange={handleImageChange}
      />
      <Button onClick={() => upload()} icon={<UploadOutlined />}>
        {t('invoice:upload')}
      </Button>
    </Modal>
  );
};
