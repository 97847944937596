import React, { useEffect } from 'react';
import { Alert, Button, Card, Descriptions, Popover, Space } from 'antd';
import { type SubmitHandler, useFieldArray, useForm } from 'react-hook-form';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';

import { updateCostsData } from '~/domain/contract/helpers/updateCostsData';
import { InputWrapper } from '~/domain/shared/components/Forms/InputWrapper';
import { TextField } from '~/domain/shared/components/Forms/ControlledInputs/TextField';
import { Select } from '~/domain/shared/components/Forms/ControlledInputs/Select';
import seniorities from '~/domain/shared/utils/seniorities.json';
import { TextArea } from '~/domain/shared/components/Forms/ControlledInputs/TextArea';
import { DatePicker } from '~/domain/shared/components/Forms/ControlledInputs/DatePicker';
import { formatCurrency } from '~/domain/shared/utils/formatCurrency';
import { EditFormGroup } from '~/domain/contract/components/EditForm/EditFormGroup';
import { useEORFeeDetails } from '~/domain/pricing/hooks/useEORFeeDetails';
import { useExchangeCurrencies } from '~/domain/pricing/hooks/useExchangeCurrencies';
import {
  EORContractDetailsModelSchema,
  type TEORContractDetailsModel,
} from '~/domain/contract/api/model/EORContractModel';
import { EditFormLayout } from '~/domain/contract/components/EditForm/EditFormLayout';
import { EditFormSection } from '~/domain/contract/components/EditForm/EditFormSection';
import { countTotalCosts } from '~/domain/contract/helpers/countTotalCosts';
import { determineEndDate } from '~/domain/contract/helpers/determineEndDate';
import { determineWorkplace } from '~/domain/contract/helpers/determineWorkplace';
import { findPaidVacationDays } from '~/domain/contract/helpers/findPaidVacationDays';
import { findWorkHours } from '~/domain/contract/helpers/findWorkHours';
import {
  useEORContractCosts,
  useUpdateEORContractDetails,
} from '~/domain/contract/hooks';
import { countries } from '~/config/countries';
import { contractCountries } from '~/config/contractCountries';
import {
  allSupportedCurrencies,
  contractCurrencies,
} from '~/config/currencies';

interface IEditForm {
  data: TEORContractDetailsModel;
  variant: 'costEstimation' | 'update';
}

export const EditForm: React.FC<IEditForm> = ({ data, variant }) => {
  const { t } = useTranslation(['EORContract', 'common']);
  const navigate = useNavigate();

  const { mutate: estimateCost, isPending: isEstimateCostPending } =
    useEORContractCosts(data.id, () => {
      navigate(`/eor-contract/${data.id}`);
    });

  const { mutate: updateContract, isPending: isUpdateContractPending } =
    useUpdateEORContractDetails(data.id, () => {
      navigate(`/eor-contract/${data.id}`);
    });

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    trigger,
    watch,
    formState: { errors, isValid },
  } = useForm<TEORContractDetailsModel>({
    defaultValues: data,
    mode: 'all',
    resolver: zodResolver(EORContractDetailsModelSchema),
  });

  const {
    fields: fieldsBonuses,
    append: appendBonuses,
    remove: removeBonuses,
  } = useFieldArray({
    control: control,
    name: 'compensation.bonuses',
  });

  const {
    fields: fieldsCompensation,
    append: appendCompensation,
    remove: removeCompensation,
  } = useFieldArray({
    control: control,
    name: 'costs.compensation.positions',
  });

  const {
    fields: fieldsDeposit,
    append: appendDeposit,
    remove: removeDeposit,
  } = useFieldArray({
    control: control,
    name: 'costs.deposit.positions',
  });

  const country = watch('employee.country');
  const currency = watch('compensation.currency');
  const { fee } = useEORFeeDetails(country);
  const { data: conversionValue, mutate: exchange } = useExchangeCurrencies();

  useEffect(() => {
    if (fee?.fiatCurrency)
      exchange({
        amount: fee.fiatValue,
        from: fee.fiatCurrency,
        to: currency,
      });
  }, [exchange, currency, fee]);

  useEffect(() => {
    if (variant === 'update') return;

    const compensation = getValues('costs.compensation');
    const deposit = getValues('costs.deposit');

    if (
      fee &&
      compensation &&
      !compensation.positions.length &&
      conversionValue
    ) {
      appendCompensation({
        name: 'Monthly fee ',
        amount: conversionValue.toFixed(2) ?? '',
      });
    }

    if (compensation && !compensation.positions.length) {
      setValue(
        'costs.compensation.currency',
        getValues('compensation.currency')
      );
    }

    if (deposit && !deposit.positions.length) {
      appendDeposit({ name: 'Deposit', amount: '0.00' });
      setValue('costs.deposit.currency', getValues('compensation.currency'));
    }

    if (!isValid) trigger();
  }, [
    appendCompensation,
    appendDeposit,
    fee,
    getValues,
    variant,
    isValid,
    trigger,
    exchange,
    conversionValue,
    setValue,
  ]);

  const onSubmit: SubmitHandler<TEORContractDetailsModel> = (data) => {
    const sendData = {
      ...data,
      costs: data?.costs && updateCostsData({ costs: data.costs }),
    };

    if (variant === 'costEstimation') estimateCost(sendData);
    if (variant === 'update') updateContract(sendData);
  };

  const deposit = getValues('costs.deposit');

  return (
    <EditFormLayout onSubmit={handleSubmit(onSubmit)}>
      <EditFormSection title={t('EORContract:contract')}>
        <EditFormGroup title={t('EORContract:employee')}>
          <InputWrapper label={t('EORContract:firstName')}>
            <TextField<TEORContractDetailsModel>
              control={control}
              name="employee.firstName"
              placeholder={t('EORContract:firstName')}
            />
          </InputWrapper>
          <InputWrapper label={t('EORContract:middleName')}>
            <TextField<TEORContractDetailsModel>
              control={control}
              name="employee.middleName"
              placeholder={t('EORContract:middleName')}
            />
          </InputWrapper>
          <InputWrapper label={t('EORContract:lastName')}>
            <TextField<TEORContractDetailsModel>
              control={control}
              name="employee.lastName"
              placeholder={t('EORContract:lastName')}
            />
          </InputWrapper>
          <InputWrapper label={t('EORContract:email')}>
            <TextField<TEORContractDetailsModel>
              control={control}
              name="employee.email"
              placeholder={t('EORContract:email')}
              type="email"
            />
          </InputWrapper>
          <InputWrapper label={t('EORContract:address')}>
            <TextField<TEORContractDetailsModel>
              control={control}
              name="employee.address"
              placeholder={t('EORContract:address')}
            />
          </InputWrapper>
          <InputWrapper label={t('EORContract:nationality')}>
            <Select<TEORContractDetailsModel>
              control={control}
              name={`employee.nationality`}
              options={countries.map((c) => ({
                value: c.code,
                label: c.name,
              }))}
            />
          </InputWrapper>
          <InputWrapper label={t('EORContract:country')}>
            <Select<TEORContractDetailsModel>
              control={control}
              name={`employee.country`}
              options={contractCountries.map((c) => ({
                value: c.code,
                label: c.name,
              }))}
              customOnChange={(value) => {
                setValue(
                  'job.workplace',
                  determineWorkplace(value, getValues('job.workplace'))
                );
              }}
            />
          </InputWrapper>
        </EditFormGroup>
        <EditFormGroup title={t('EORContract:job')}>
          <InputWrapper label={t('EORContract:entityName')}>
            <TextField<TEORContractDetailsModel>
              control={control}
              name="job.entity"
              placeholder={t('EORContract:entityName')}
            />
          </InputWrapper>
          <InputWrapper label={t('EORContract:contractName')}>
            <TextField<TEORContractDetailsModel>
              control={control}
              name="job.name"
              placeholder={t('EORContract:contractName')}
            />
          </InputWrapper>
          <InputWrapper
            label={t('EORContract:workplace')}
            hidden={watch('employee.country') !== 'VN'}
          >
            <TextField<TEORContractDetailsModel>
              control={control}
              name="job.workplace"
              placeholder={t('EORContract:workplace')}
            />
          </InputWrapper>
          <InputWrapper label={t('EORContract:jobTitle')}>
            <TextField<TEORContractDetailsModel>
              control={control}
              name="job.title"
              placeholder={t('EORContract:jobTitle')}
            />
          </InputWrapper>
          <InputWrapper label={t('EORContract:seniority')}>
            <Select<TEORContractDetailsModel>
              control={control}
              name={`job.seniority`}
              options={seniorities.map((s) => ({ label: s, value: s }))}
            />
          </InputWrapper>
          <InputWrapper label={t('EORContract:scopeOfWork')}>
            <TextArea<TEORContractDetailsModel>
              control={control}
              name={`job.scope`}
              placeholder={t('EORContract:scopeOfWork')}
            />
          </InputWrapper>
        </EditFormGroup>
        <EditFormGroup title={t('EORContract:compensation')}>
          <InputWrapper label={t('EORContract:currency')}>
            <Select<TEORContractDetailsModel>
              control={control}
              name={`compensation.currency`}
              options={contractCurrencies.map((c) => ({
                label: `${c.code} - ${c.name}`,
                value: c.code,
              }))}
            />
          </InputWrapper>
          <InputWrapper label={t('EORContract:salaryType')}>
            <Select<TEORContractDetailsModel>
              control={control}
              name={`compensation.salaryType`}
              options={[
                { label: t('EORContract:annual'), value: 'annual' },
                { label: t('EORContract:monthly'), value: 'monthly' },
              ]}
            />
          </InputWrapper>
          <InputWrapper label={t('EORContract:salary')}>
            <TextField<TEORContractDetailsModel>
              control={control}
              name={`compensation.salary`}
              placeholder={t('EORContract:salary')}
            />
          </InputWrapper>
          <InputWrapper label={t('EORContract:startDate')}>
            <DatePicker<TEORContractDetailsModel>
              control={control}
              name={`compensation.startDate`}
              placeholder={t('EORContract:startDate')}
            />
          </InputWrapper>
          <InputWrapper label={t('EORContract:employmentType')}>
            <Select<TEORContractDetailsModel>
              control={control}
              name={`compensation.employmentType`}
              placeholder={t('EORContract:employmentType')}
              options={[
                { label: t('EORContract:fulltime'), value: 'fulltime' },
                { label: t('EORContract:parttime'), value: 'parttime' },
              ]}
              customOnChange={(value) => {
                setValue(
                  'compensation.hoursPerWeek',
                  findWorkHours(
                    value,
                    getValues('compensation.hoursPerWeek'),
                    getValues('employee.country')
                  )
                );
              }}
            />
          </InputWrapper>
          <InputWrapper label={t('EORContract:workHoursPerWeek')}>
            <TextField<TEORContractDetailsModel>
              control={control}
              name={`compensation.hoursPerWeek`}
              placeholder={t('EORContract:workHoursPerWeek')}
              disabled={watch('compensation.employmentType') === 'fulltime'}
            />
          </InputWrapper>
          <InputWrapper label={t('EORContract:timeOff')}>
            <Select<TEORContractDetailsModel>
              control={control}
              name={`compensation.timeOffType`}
              placeholder="Time off"
              options={[
                { label: t('EORContract:standard'), value: 'standard' },
                { label: t('EORContract:specific'), value: 'specific' },
              ]}
              customOnChange={(value) => {
                setValue(
                  'compensation.paidVacationDays',
                  findPaidVacationDays(
                    value,
                    getValues('compensation.paidVacationDays'),
                    getValues('employee.country')
                  )
                );
              }}
            />
          </InputWrapper>
          <InputWrapper label={t('EORContract:paidVacationDays')}>
            <TextField<TEORContractDetailsModel>
              type="number"
              control={control}
              name={`compensation.paidVacationDays`}
              placeholder={t('EORContract:paidVacationDays')}
              disabled={watch('compensation.timeOffType') === 'standard'}
            />
          </InputWrapper>
          <InputWrapper label={t('EORContract:contractTerm')}>
            <Select<TEORContractDetailsModel>
              control={control}
              name="compensation.contractTermType"
              placeholder={t('EORContract:contractTerm')}
              options={[
                { label: t('EORContract:definite'), value: 'definite' },
                { label: t('EORContract:indefinite'), value: 'indefinite' },
              ]}
              customOnChange={(value) => {
                setValue('compensation.endDate', determineEndDate(value));
              }}
            />
          </InputWrapper>
          <InputWrapper
            label={t('EORContract:endDate')}
            hidden={watch('compensation.contractTermType') === 'indefinite'}
          >
            <DatePicker<TEORContractDetailsModel>
              control={control}
              name="compensation.endDate"
              placeholder={t('EORContract:endDate')}
            />
          </InputWrapper>
          <InputWrapper label={t('EORContract:probationPeriod')}>
            <TextField<TEORContractDetailsModel>
              control={control}
              name="compensation.probationPeriod"
              placeholder={t('EORContract:probationPeriod')}
            />
          </InputWrapper>
        </EditFormGroup>
      </EditFormSection>
      <EditFormSection title={t('EORContract:compensation')}>
        {variant === 'costEstimation' && (
          <>
            <EditFormGroup
              title={t('EORContract:estimatedGeneralMonthlyPayroll')}
            >
              <Descriptions
                column={2}
                title={t('EORContract:userInfo')}
                bordered
                style={{ marginBottom: '12px' }}
              >
                <Descriptions.Item label={t('EORContract:total')}>
                  {countTotalCosts(
                    watch('costs.compensation.positions'),
                    watch('costs.compensation.currency') ||
                      watch('compensation.currency')
                  )}
                </Descriptions.Item>
                <Descriptions.Item>
                  <Select<TEORContractDetailsModel>
                    control={control}
                    name={`costs.compensation.currency`}
                    options={allSupportedCurrencies.map((c) => ({
                      label: `${c.code} - ${c.name}`,
                      value: c.code,
                    }))}
                  />
                </Descriptions.Item>
                <Descriptions.Item label={t('EORContract:grossSalary')}>
                  {formatCurrency(
                    Number(watch('compensation.salary')),
                    watch('costs.compensation.currency')
                  )}
                </Descriptions.Item>
              </Descriptions>
              <Card
                actions={[
                  <PlusOutlined
                    key="appendCompensation"
                    onClick={() => appendCompensation({ name: '', amount: '' })}
                  />,
                ]}
              >
                {fieldsCompensation.map((item, index) => (
                  <InputWrapper key={item.id}>
                    <Space direction="horizontal">
                      <TextField<TEORContractDetailsModel>
                        control={control}
                        name={`costs.compensation.positions.${index}.name`}
                        placeholder={t('EORContract:name')}
                      />
                      <TextField<TEORContractDetailsModel>
                        control={control}
                        name={`costs.compensation.positions.${index}.amount`}
                        placeholder={t('EORContract:amount')}
                      />
                      <Button
                        danger
                        onClick={() => {
                          if (
                            getValues('costs.compensation.positions').length > 1
                          )
                            return removeCompensation(index);
                        }}
                      >
                        <DeleteOutlined />
                      </Button>
                    </Space>
                  </InputWrapper>
                ))}
              </Card>
            </EditFormGroup>
            <EditFormGroup title={t('EORContract:deposit')}>
              <Descriptions
                column={2}
                title={t('EORContract:userInfo')}
                bordered
                style={{ marginBottom: '12px' }}
              >
                <Descriptions.Item label={t('EORContract:total')}>
                  {countTotalCosts(
                    watch('costs.deposit.positions'),
                    watch('costs.deposit.currency') ||
                      watch('compensation.currency')
                  )}
                </Descriptions.Item>
                <Descriptions.Item>
                  <Select<TEORContractDetailsModel>
                    control={control}
                    name={`costs.deposit.currency`}
                    options={allSupportedCurrencies.map((c) => ({
                      label: `${c.code} - ${c.name}`,
                      value: c.code,
                    }))}
                  />
                </Descriptions.Item>
              </Descriptions>
              <Card
                actions={[
                  <PlusOutlined
                    key="appendDeposit"
                    onClick={() => appendDeposit({ name: '', amount: '' })}
                  />,
                ]}
              >
                {fieldsDeposit.map((item, index) => (
                  <InputWrapper key={item.id}>
                    <Space direction="horizontal">
                      <TextField<TEORContractDetailsModel>
                        placeholder={t('EORContract:name')}
                        control={control}
                        name={`costs.deposit.positions.${index}.name`}
                      />
                      <TextField<TEORContractDetailsModel>
                        placeholder={t('EORContract:amount')}
                        control={control}
                        name={`costs.deposit.positions.${index}.amount`}
                      />
                      <Button
                        danger
                        onClick={() => {
                          if (deposit && deposit.positions.length > 1)
                            return removeDeposit(index);
                        }}
                        disabled={deposit && deposit.positions.length === 1}
                      >
                        <DeleteOutlined />
                      </Button>
                    </Space>
                  </InputWrapper>
                ))}
              </Card>
            </EditFormGroup>
          </>
        )}
        <EditFormGroup title={t('EORContract:variableCompensation')}>
          <Card
            actions={[
              <PlusOutlined
                key="appendBonuses"
                onClick={() =>
                  appendBonuses({ name: '', type: 'flatrate', value: '' })
                }
              />,
            ]}
          >
            {fieldsBonuses.map((item, index) => (
              <Card key={item.id}>
                <InputWrapper label={t('EORContract:name')}>
                  <TextField<TEORContractDetailsModel>
                    control={control}
                    name={`compensation.bonuses.${index}.name`}
                    placeholder={t('EORContract:name')}
                  />
                </InputWrapper>
                <InputWrapper label={t('EORContract:type')}>
                  <Select<TEORContractDetailsModel>
                    control={control}
                    name={`compensation.bonuses.${index}.type`}
                    options={[
                      { label: t('EORContract:amount'), value: 'flatrate' },
                      {
                        label: t('EORContract:percent'),
                        value: 'percentage',
                      },
                    ]}
                  />
                </InputWrapper>
                <InputWrapper label={t('EORContract:value')}>
                  <TextField<TEORContractDetailsModel>
                    control={control}
                    name={`compensation.bonuses.${index}.value`}
                    placeholder={t('EORContract:value')}
                  />
                </InputWrapper>
                <Button danger onClick={() => removeBonuses(index)}>
                  <DeleteOutlined />
                </Button>
              </Card>
            ))}
          </Card>
        </EditFormGroup>
      </EditFormSection>
      <>
        {!!Object.keys(errors).length && (
          <Alert
            showIcon
            type="error"
            style={{ marginBottom: '16px', cursor: 'pointer' }}
            message={
              <span>
                <Popover
                  trigger="hover"
                  content={
                    <pre
                      style={{
                        display: 'block',
                        unicodeBidi: 'embed',
                        fontFamily: 'monospace',
                        whiteSpace: 'pre',
                        fontSize: '11px',
                        padding: '0 32px 0 12px',
                      }}
                    >
                      {JSON.stringify(errors, undefined, 4)}
                    </pre>
                  }
                >
                  <span>{t('EORContract:invalidForm')}</span>
                </Popover>
              </span>
            }
          />
        )}
        <Space size={'small'}>
          <Button onClick={() => navigate(-1)}>{t('common:back')}</Button>
          <Button
            type="primary"
            htmlType="submit"
            loading={isEstimateCostPending || isUpdateContractPending}
            disabled={!isValid}
          >
            {t('EORContract:submit')}
          </Button>
        </Space>
      </>
    </EditFormLayout>
  );
};
