interface IProps {
  [key: string]: unknown;
}

export const filterEmptyValues = (obj: IProps) =>
  Object.fromEntries(
    Object.entries(obj).filter(
      ([_, value]) => value !== '' && value !== undefined && value !== null
    )
  );
