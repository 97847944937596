import { z } from 'zod';

import { invoiceTypeArr } from '~/domain/invoices/types/invoiceType';
import { invoiceStatuses } from '~/domain/invoices/types/invoiceStatuses';
import { TAllSupportedCurrencies } from '~/domain/shared/types/currencies';

export const amountRegex = /^\d+(\.\d{1,2})?$/;
export const dateRegex = /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])$/;
export const requiredString = /.+/;

const paymentCurrencyCodeSchema = z.custom<TAllSupportedCurrencies>();

const modifyStringArray = (arr: string[]): string[] => {
  if (arr.length > 5) {
    return arr.slice(0, 5);
  }
  if (arr.length < 5) {
    const diff = 5 - arr.length;
    const emptyStrings = Array.from({ length: diff }, () => '');
    return arr.concat(emptyStrings);
  }
  return arr;
};

export const invoiceDetailsSchema = z.object({
  ID: z.number(),
  status: z.enum(invoiceStatuses),
  type: z.enum(invoiceTypeArr),
  contractId: z.number(),
  number: z.string().regex(requiredString),
  title: z.string().regex(requiredString),
  currency: paymentCurrencyCodeSchema,
  data: z.object({
    issueDate: z.string(),
    dueDate: z.string().regex(dateRegex),
    totalDue: z.string().regex(amountRegex),
    contract: z.string(),
    scope: z.string(),
    billFrom: z.array(z.string()).transform(modifyStringArray),
    billTo: z.array(z.string()).transform(modifyStringArray),
    positions: z
      .union([
        z.null(),
        z.array(
          z.object({
            name: z.string().default(''),
            amount: z.string().regex(amountRegex).default(''),
            vatRate: z.string().regex(amountRegex).default(''),
            grossValue: z.string().regex(amountRegex).default(''),
            currency: paymentCurrencyCodeSchema,
          })
        ),
      ])
      .transform((val) => (val ? val : [])),
    logoPath: z.union([z.string().url(), z.undefined()]),
  }),
  generateDate: z.string().regex(dateRegex),
  approveDate: z.union([z.string().regex(dateRegex), z.undefined()]),
  paidDate: z.union([z.string().regex(dateRegex), z.undefined()]),
  base64logo: z.union([z.instanceof(FileList), z.null()]).default(null),
});

export type TInvoiceDetails = z.TypeOf<typeof invoiceDetailsSchema>;

export const prepareInvoiceDetailsModel = (data: unknown) => {
  return invoiceDetailsSchema.parse(data);
};
