import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

export const useListFilter = <
  T extends Record<string, string | undefined | number>,
>(
  INIT_FILTERS: T
) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const filterParams: T = useMemo(() => {
    return {
      ...INIT_FILTERS,
      ...Object.entries(INIT_FILTERS).reduce((result, [key, value]) => {
        if (key === 'page' || key == 'pageSize') {
          result[key] = Number(searchParams.get(key)) || Number(value);
        } else {
          result[key] = searchParams.get(key) || value;
        }
        return result;
      }, {}),
    };
  }, [searchParams, INIT_FILTERS]);

  const handleSearchParams = <K extends keyof T & string>(
    key: K,
    value: T[K]
  ) => {
    setSearchParams((params) => {
      if (key !== 'page' && INIT_FILTERS.page) {
        params.set('page', String(INIT_FILTERS.page));
        params.set('pageSize', String(INIT_FILTERS.pageSize));
      }

      !value ? params.delete(key) : params.set(key, String(value));

      return params;
    });
  };

  const resetFilters = <K extends keyof T & string>(k?: K[]) => {
    setSearchParams((params) => {
      for (const key of k ?? Object.keys(INIT_FILTERS)) {
        const initialValue = INIT_FILTERS[key];
        if (initialValue === undefined || initialValue === '')
          params.delete(key);
        else params.set(key, String(initialValue));
      }
      return params;
    });
  };

  return {
    handleSearchParams,
    resetFilters,
    filterParams,
    ...filterParams,
  };
};
