import {
  ArrowLeftOutlined,
  CheckOutlined,
  CloseOutlined,
  EditOutlined,
  FastForwardOutlined,
  InfoCircleOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import { Button, Card, Descriptions, Popover, Space, Spin, Table } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { calculateCurrencyPositions } from '~/domain/invoices/utils/calculateCurrencyPositions';
import { TInvoiceDetails } from '~/domain/invoices/api/model/invoiceDetails';
import { InvoiceVoucherModal } from '~/domain/invoices/components/InvoiceVoucherModal';
import { useInvoiceApprove } from '~/domain/invoices/hooks/useInvoiceApprove';
import { useInvoiceCancel } from '~/domain/invoices/hooks/useInvoiceCancel';
import { useInvoiceSkip } from '~/domain/invoices/hooks/useInvoiceSkip';
import { useExchangeMultipleCurrencies } from '~/domain/shared/hooks/useExchangeMultipleCurrencies';
import { formatCurrency } from '~/domain/shared/utils/formatCurrency';

type TInvoiceDetailsProps = {
  data: TInvoiceDetails;
};

export const InvoiceDetails = ({ data }: TInvoiceDetailsProps) => {
  const { t } = useTranslation(['invoice']);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { mutate: approve, isPending: isApprovePending } = useInvoiceApprove(
    data.ID
  );
  const { mutate: cancel, isPending: isCancelPending } = useInvoiceCancel(
    data.ID
  );
  const { mutate: skip, isPending: isSkipPending } = useInvoiceSkip(data.ID);

  const { totaledCurrencies } = calculateCurrencyPositions(data.data.positions);

  const { sum, isPending: isPendingExchange } = useExchangeMultipleCurrencies(
    totaledCurrencies,
    'USD'
  );

  const calculateTotalDisplay = () =>
    isPendingExchange ? (
      <Spin />
    ) : (
      `${t('invoice:total')} ≈ ${Number(sum ?? 0).toFixed(2)} USD`
    );

  const columns = [
    {
      title: t('invoice:name'),
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: t('invoice:amount'),
      dataIndex: 'amount',
      key: 'amount',
    },
    {
      title: t('invoice:vatRate'),
      dataIndex: 'vatRate',
      key: 'vatRate',
    },
    {
      title: t('invoice:grossValue'),
      dataIndex: 'grossValue',
      key: 'grossValue',
    },
    {
      title: 'currency',
      dataIndex: 'currency',
      key: 'currency',
    },
  ];

  return (
    <div style={{ width: '100%' }}>
      <InvoiceVoucherModal
        id={data.ID}
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      />
      <Space>
        <Link to="/invoice">
          <Button type="primary" icon={<ArrowLeftOutlined />}>
            {t('invoice:backInvoiceList')}
          </Button>
        </Link>
        <Popover
          trigger="click"
          content={
            <p style={{ fontSize: 10, padding: 12 }}>{JSON.stringify(data)}</p>
          }
        >
          <Button type="default" icon={<InfoCircleOutlined />} />
        </Popover>
      </Space>

      <section style={{ marginTop: 32, width: '100%' }}>
        <Space direction="vertical" size="large">
          {Boolean(data.data.logoPath) && (
            <Card>
              <img src={data.data.logoPath} className="max-w-xs" />
            </Card>
          )}
          <Card>
            <Descriptions title={t('invoice:invoiceDetails')} column={1}>
              <Descriptions.Item label={t('invoice:invoiceNo')}>
                {data.number}
              </Descriptions.Item>
              <Descriptions.Item label={t('invoice:invoiceTitle')}>
                {data.title}
              </Descriptions.Item>
              <Descriptions.Item label={t('invoice:contractName')}>
                {data.data.contract}
              </Descriptions.Item>
              <Descriptions.Item label={t('invoice:type')}>
                {data.type}
              </Descriptions.Item>
              <Descriptions.Item label={t('invoice:status')}>
                {data.status}
              </Descriptions.Item>
              <Descriptions.Item label={t('invoice:issueDate')}>
                {data.data.issueDate}
              </Descriptions.Item>
              <Descriptions.Item label={t('invoice:dueDate')}>
                {data.data.dueDate}
              </Descriptions.Item>
              <Descriptions.Item label={t('invoice:totalDue')}>
                {formatCurrency(+data.data.totalDue, data.currency)}
              </Descriptions.Item>
            </Descriptions>
          </Card>
          <Space>
            <Card>
              <Descriptions title={t('invoice:from')} column={1}>
                {data.data.billFrom.map((item, index) => (
                  <Descriptions.Item
                    key={index}
                    label={t('invoice:line', { line: index + 1 })}
                  >
                    {item}
                  </Descriptions.Item>
                ))}
              </Descriptions>
            </Card>

            <Card>
              <Descriptions title={t('invoice:to')} column={1}>
                {data.data.billTo.map((item, index) => (
                  <Descriptions.Item
                    key={index}
                    label={t('invoice:line', { line: index + 1 })}
                  >
                    {item}
                  </Descriptions.Item>
                ))}
              </Descriptions>
            </Card>
          </Space>
          <Card>
            <Descriptions title={t('invoice:contract')} column={1}>
              <Descriptions.Item label={t('invoice:name')}>
                {data.data.contract}
              </Descriptions.Item>
              <Descriptions.Item label={t('invoice:scope')}>
                {data.data.scope}
              </Descriptions.Item>
            </Descriptions>
          </Card>
          <Table
            dataSource={data.data.positions.map((item, index) => ({
              ...item,
              key: index,
            }))}
            columns={columns}
            pagination={false}
            footer={calculateTotalDisplay}
          />
          <Space style={{ marginTop: 48 }}>
            {['new'].includes(data.status) && (
              <Link to={`/invoice/${data.ID}/edit`}>
                <Button type="primary" icon={<EditOutlined />}>
                  {t('invoice:edit')}
                </Button>
              </Link>
            )}
            {['new'].includes(data.status) && (
              <Button
                type="primary"
                icon={<CheckOutlined />}
                loading={isApprovePending}
                onClick={() => {
                  approve(data);
                }}
              >
                {t('invoice:approve')}
              </Button>
            )}
            {!['skipped', 'canceled', 'paid'].includes(data.status) && (
              <Button
                type="primary"
                icon={<CloseOutlined />}
                loading={isCancelPending}
                onClick={() => {
                  cancel();
                }}
              >
                {t('invoice:cancel')}
              </Button>
            )}
            {!['skipped', 'canceled', 'paid'].includes(data.status) && (
              <Button
                type="primary"
                icon={<FastForwardOutlined />}
                loading={isSkipPending}
                onClick={() => skip()}
              >
                {t('invoice:skip')}
              </Button>
            )}
            {['approved'].includes(data.status) && (
              <Button
                type="primary"
                icon={<UploadOutlined />}
                onClick={() => setIsModalOpen(true)}
              >
                {t('invoice:uploadPaymentVoucher')}
              </Button>
            )}
            {!['new'].includes(data.status) && (
              <Button
                type="primary"
                target="_blank"
                href={`${
                  import.meta.env.VITE_APP_BASE_URL
                }/operators/invoices/${data.ID}/content`}
              >
                {t('invoice:viewInvoice')}
              </Button>
            )}
            {['paid'].includes(data.status) && (
              <Button
                type="primary"
                href={`${
                  import.meta.env.VITE_APP_BASE_URL
                }/operators/invoices/${data.ID}/employer/received/content`}
              >
                {t('invoice:viewDepositVoucher')}
              </Button>
            )}
          </Space>
        </Space>
      </section>
    </div>
  );
};
