import axios from 'axios';

import { ENDPOINTS } from '~/domain/shared/utils/endpoints';
import { type ILogoutDTO } from '~/domain/user/api/dto/logoutDTO';

export const logout = async () => {
  const res = await axios.get<ILogoutDTO>(ENDPOINTS.AUTH.LOGOUT);

  return res.data;
};
