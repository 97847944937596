import { useQuery } from '@tanstack/react-query';

import { exchangeMultipleCurrencies } from '~/domain/shared/api';

export const useExchangeMultipleCurrencies = (
  from: {
    total: string;
    currency: string;
  }[],
  to: string
) => {
  const query = useQuery({
    queryFn: () => exchangeMultipleCurrencies(from, to),
    queryKey: [JSON.stringify(from)],
  });

  const sum = query.data?.reduce(
    (accumulator: number, currentValue: number) => accumulator + currentValue,
    0
  );

  return {
    ...query,
    sum,
  };
};
