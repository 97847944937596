import dayjs from 'dayjs';

const FULL_DATE = 'YYYY-MM-DD';
const FULL_DATE_TIME = 'YYYY-MM-DD HH:mm';

export const dateFormat = {
  date: (date: Date | null | undefined | string) => {
    if (!date) return '-';
    return dayjs(date).format(FULL_DATE);
  },

  dateTime: (date: Date | null | undefined | string) => {
    if (!date) return '-';
    return dayjs(date).format(FULL_DATE_TIME);
  },
};
