import { z } from 'zod';

export const payGoSchema = z.object({
  id: z.number(),
  createdAt: z.string(),
  updatedAt: z.string(),
  general: z.object({
    entity: z.string(),
    name: z.string(),
    taxResidence: z.string(),
    country: z.string(),
    jobTitle: z.string(),
    seniority: z.string(),
    startDate: z.string(),
    scope: z.string(),
    contractorName: z.string(),
    contractorEmail: z.string().email(),
  }),
  dates: z.object({
    firstPayment: z.object({
      date: z.string(),
      type: z.string(),
      customAmount: z.string().optional(),
    }),
    noticePeriodInDays: z.number(),
    contractTermType: z.string(),
    finalPayment: z
      .object({
        date: z.string(),
        type: z.string(),
        customAmount: z.string().optional(),
      })
      .optional(),
  }),
  payment: z.object({
    currency: z.string(),
    paymentCurrency: z.string(),
    feeCurrency: z.string(),
    rate: z.string(),
    frequency: z.string(),
    cycleEnds: z.number(),
    paymentDue: z.string(),
    customPaymentDue: z.number().optional(),
    semimonthlyType: z.string(),
    payWeekendAhead: z.boolean(),
    monthlyType: z.string(),
  }),
  compliance: z.object({
    useIRContract: z.string(),
  }),
  type: z.literal('paygo'),
  status: z.string(),
  daysLeft: z.number().optional(),
  organization: z.object({
    id: z.number(),
    orgId: z.string(),
    name: z.string(),
    legalName: z.string(),
    address: z.string(),
    headquarter: z.string(),
    type: z.string(),
    gstRegNo: z.string(),
    croNumber: z.string(),
    signatoryName: z.string(),
    signatoryEmail: z.string().email(),
    vatId: z.string(),
    size: z.string(),
  }),
});

export type TPayGoDetailsDTO = z.TypeOf<typeof payGoSchema>;
