import React from 'react';
import { Space } from 'antd';
import Title from 'antd/es/typography/Title';

import styles from '~/domain/contract/components/EditForm/EditFormSection/EditFormSection.module.scss';

interface IEditFormSectionProps {
  title: string;
}

export const EditFormSection: React.FC<
  IEditFormSectionProps & React.PropsWithChildren
> = ({ title, children }) => {
  return (
    <Space direction="vertical" style={{ width: '100%' }} size="large">
      <Title level={4} className={styles.title}>
        {title}
      </Title>
      {children}
    </Space>
  );
};
