import { useQueryClient, useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';

import { logout } from '~/domain/user/api/logout';
import { QUERY_KEYS } from '~/domain/shared/utils/queryKeys';
import { PATH } from '~/domain/shared/utils/path';

export const useLogout = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  return useMutation({
    mutationKey: [QUERY_KEYS.AUTH.LOGOUT],
    mutationFn: logout,
    retry: 0,
    onSettled: () => {
      queryClient.clear();
      navigate(PATH.LOG_IN);
    },
  });
};
