import axios from 'axios';

import {
  prepareInvoiceDetailsModel,
  TInvoiceDetails,
} from '~/domain/invoices/api/model/invoiceDetails';

export const fileToBase64 = (file: File | Blob): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => {
      resolve(reader.result as string);
    };

    reader.readAsDataURL(file);
    reader.onerror = reject;
  });

export const setInvoiceAsApproved = async (data: TInvoiceDetails) => {
  let logo: string | undefined = undefined;

  if (data.base64logo?.[0]) {
    const logoWithHeader = await fileToBase64(data.base64logo[0]);
    logo = logoWithHeader.split(',')[1];
  }

  const response = await axios.post(`operators/invoices/${data.ID}/approve`, {
    issueDate: data.data.issueDate,
    dueDate: data.data.dueDate,
    totalDue: data.data.totalDue,
    contractName: data.data.contract,
    contractScope: data.data.scope,
    logo: logo,
    billFrom: data.data.billFrom,
    billTo: data.data.billTo,
    positions: data.data.positions,
    currency: data.currency,
  });

  return prepareInvoiceDetailsModel(response.data);
};
