import axios from 'axios';

import { prepareInvoiceListModel } from '~/domain/invoices/api/model/invoiceList';

export const getInvoiceList = async (params) => {
  const response = await axios.get(`/operators/invoices`, {
    params,
  });

  return prepareInvoiceListModel(response.data);
};
