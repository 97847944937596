import React from 'react';
import { Input, type InputProps, Tooltip, Typography } from 'antd';
import {
  Controller,
  type Control,
  type FieldPath,
  type FieldValues,
} from 'react-hook-form';
import { InfoCircleOutlined } from '@ant-design/icons';

type TTextField<T extends FieldValues> = {
  name: FieldPath<T>;
  control: Control<T>;
  type?: string;
  placeholder?: string;
  readOnly?: boolean;
  disabled?: boolean;
  extraInfo?: string;
  displayErrorMessage?: boolean;
  onCustomChange?: (val: string) => void;
} & InputProps;

export const TextField = <T extends FieldValues>({
  name,
  control,
  type,
  placeholder,
  readOnly,
  disabled,
  extraInfo,
  displayErrorMessage = false,
  onCustomChange,
  ...rest
}: TTextField<T>) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { value, onChange }, fieldState }) => (
        <>
          <Input
            size="large"
            style={{ width: '100%' }}
            value={value || ''}
            onChange={(e) => {
              onChange(type === 'number' ? +e.target.value : e.target.value);
              onCustomChange && onCustomChange(e.target.value);
            }}
            type={type || 'text'}
            status={fieldState.error ? 'error' : ''}
            autoComplete="off"
            placeholder={placeholder}
            readOnly={readOnly}
            disabled={disabled}
            suffix={
              extraInfo && (
                <Tooltip title={extraInfo}>
                  <InfoCircleOutlined style={{ color: 'rgba(0,0,0,.45)' }} />
                </Tooltip>
              )
            }
            {...rest}
          />
          {displayErrorMessage && fieldState?.error?.message && (
            <Typography.Text className="text-red-500">
              {fieldState?.error?.message}
            </Typography.Text>
          )}
        </>
      )}
    />
  );
};
