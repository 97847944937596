import React from 'react';
import { Spin } from 'antd';
import { Navigate, useParams } from 'react-router-dom';

import { EditForm } from '~/domain/contract/components/EditForm';
import { PATH } from '~/domain/shared/utils/path';
import { useEORContractDetails } from '~/domain/contract/hooks';

export const UpdateEORPage: React.FC = () => {
  const { id } = useParams();
  const { isSuccess, isLoading, contract } = useEORContractDetails(id);

  if (isLoading) return <Spin />;
  if (isSuccess && contract?.status === 'eornotsent')
    return <EditForm variant="update" data={contract} />;
  return <Navigate to={`${PATH.EOR_CONTRACT}/${id}`} replace={true} />;
};
