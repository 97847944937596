import { useQuery } from '@tanstack/react-query';

import { getEORFeeDetails } from '~/domain/pricing/api';
import { QUERY_KEYS } from '~/domain/shared/utils/queryKeys';

export const useEORFeeDetails = (countryCode: string) => {
  const query = useQuery({
    queryFn: () => getEORFeeDetails(countryCode),
    queryKey: [QUERY_KEYS.PRICING.LIST, countryCode],
  });

  return { ...query, fee: query.data?.data.find(Boolean) };
};
