import React from 'react';
import { Input } from 'antd';
import {
  Controller,
  type Control,
  type FieldPath,
  type FieldValues,
} from 'react-hook-form';

type TTextArea<T extends FieldValues> = {
  name: FieldPath<T>;
  control: Control<T>;
  type?: string;
  placeholder?: string;
  readOnly?: boolean;
  disabled?: boolean;
};

export const TextArea = <T extends FieldValues>({
  name,
  control,
  placeholder,
  readOnly,
  disabled,
}: TTextArea<T>) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { value, onChange }, fieldState }) => (
        <Input.TextArea
          size="large"
          style={{ width: '100%' }}
          value={value || ''}
          onChange={onChange}
          rows={12}
          status={fieldState.error ? 'error' : ''}
          autoComplete="off"
          placeholder={placeholder}
          readOnly={readOnly}
          disabled={disabled}
        />
      )}
    />
  );
};
