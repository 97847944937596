import React from 'react';
import {
  DesktopOutlined,
  FileDoneOutlined,
  ProfileOutlined,
  UserOutlined,
  VerifiedOutlined,
  TeamOutlined,
  WalletOutlined,
  FileSearchOutlined,
} from '@ant-design/icons';
import { Layout as AntdLayout, Menu, type MenuProps } from 'antd';
import { NavLink, useLocation } from 'react-router-dom';

import { i18trans } from '~/translation/i18n';
import IFRemoteLogo from '~/domain/shared/assets/logo.svg';
import { PATH } from '~/domain/shared/utils/path';

const { Sider } = AntdLayout;

type MenuItem = Required<MenuProps>['items'][number];

function getItem(
  label: React.ReactNode,
  key: string,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: 'group'
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type,
  } as MenuItem;
}

const menuItems: MenuItem[] = [
  getItem(
    i18trans.t('contractManagement', { ns: 'common' }),
    'contract',
    <DesktopOutlined />,
    [
      getItem(
        <NavLink to={PATH.EOR_CONTRACT} key={PATH.EOR_CONTRACT}>
          <span>{i18trans.t('eorContract', { ns: 'common' })}</span>
        </NavLink>,
        PATH.EOR_CONTRACT
      ),
      getItem(
        <NavLink to={PATH.FIXED_RATE_CONTRACT} key={PATH.FIXED_RATE_CONTRACT}>
          <span>{i18trans.t('frContract', { ns: 'common' })}</span>
        </NavLink>,
        PATH.FIXED_RATE_CONTRACT
      ),
      getItem(
        <NavLink to={PATH.PAY_GO_CONTRACT} key={PATH.PAY_GO_CONTRACT}>
          <span>{i18trans.t('paygo', { ns: 'common' })}</span>
        </NavLink>,
        PATH.PAY_GO_CONTRACT
      ),
    ]
  ),
  getItem(
    <NavLink to={PATH.ORGANIZATION} key={PATH.ORGANIZATION}>
      <span>{i18trans.t('organizationManagement', { ns: 'common' })}</span>
    </NavLink>,
    PATH.ORGANIZATION,
    <UserOutlined />
  ),
  getItem(
    <NavLink to={PATH.KYC_LIST} key={PATH.KYC_LIST}>
      <span>{i18trans.t('kycManagement', { ns: 'common' })}</span>
    </NavLink>,
    PATH.KYC_LIST,
    <VerifiedOutlined />
  ),
  getItem(
    <NavLink to={PATH.INVOICE} key={PATH.INVOICE}>
      <span>{i18trans.t('invoiceManagement', { ns: 'common' })}</span>
    </NavLink>,
    PATH.INVOICE,
    <FileDoneOutlined />
  ),
  getItem(
    <NavLink to={PATH.RECEIPT} key={PATH.RECEIPT}>
      <span>{i18trans.t('receiptManagement', { ns: 'common' })}</span>
    </NavLink>,
    PATH.RECEIPT,
    <ProfileOutlined />
  ),
  getItem(
    <NavLink to={PATH.EMPLOYEE} key={PATH.EMPLOYEE}>
      <span>{i18trans.t('employeeManagement', { ns: 'common' })}</span>
    </NavLink>,
    PATH.EMPLOYEE,
    <UserOutlined />
  ),
  getItem(
    <NavLink to={PATH.EMPLOYER} key={PATH.EMPLOYER}>
      <span>{i18trans.t('employerManagement', { ns: 'common' })}</span>
    </NavLink>,
    PATH.EMPLOYER,
    <UserOutlined />
  ),
  getItem(
    i18trans.t('recruitmentManagement', { ns: 'common' }),
    'recruitmentManagement',
    <TeamOutlined />,
    [
      getItem(
        <NavLink to={PATH.RECRUITMENT_LIST} key={PATH.RECRUITMENT_LIST}>
          <span>{i18trans.t('recruitmentList', { ns: 'common' })}</span>
        </NavLink>,
        PATH.RECRUITMENT_LIST
      ),
      getItem(
        <NavLink to={PATH.TALENT_POOL} key={PATH.TALENT_POOL}>
          <span>{i18trans.t('talentPool', { ns: 'common' })}</span>
        </NavLink>,
        PATH.TALENT_POOL
      ),
    ]
  ),
  getItem(
    <NavLink to={PATH.PRICING_MANAGEMENT} key={PATH.PRICING_MANAGEMENT}>
      <span>{i18trans.t('pricingManagement', { ns: 'common' })}</span>
    </NavLink>,
    PATH.PRICING_MANAGEMENT,
    <WalletOutlined />
  ),
  getItem(
    <NavLink to={PATH.VISA_SERVICE} key={PATH.VISA_SERVICE}>
      <span>{i18trans.t('visaService', { ns: 'common' })}</span>
    </NavLink>,
    PATH.VISA_SERVICE,
    <FileSearchOutlined />
  ),
];

export const SideNav = () => {
  const { pathname } = useLocation();

  const selectedKey = `/${pathname.split('/')[1]}`;

  return (
    <Sider
      breakpoint="lg"
      width={250}
      collapsedWidth="0"
      trigger={null}
      collapsible
    >
      <div className="h-16 bg-white flex items-center pl-3">
        <img src={IFRemoteLogo} alt="remote" />
      </div>

      <Menu
        selectedKeys={[selectedKey]}
        defaultOpenKeys={['contract']}
        mode="inline"
        theme="dark"
        className="full-height overflow-auto"
        items={menuItems}
      />
    </Sider>
  );
};
