import type { TAllSupportedCurrencies } from '~/domain/shared/types/currencies';

interface IPosition {
  name: string;
  amount: string;
}

interface ICompensationData {
  currency: TAllSupportedCurrencies;
  positions: IPosition[];
}

interface IDepositData {
  currency: TAllSupportedCurrencies;
  positions: IPosition[];
}

interface ICostsData {
  compensation: ICompensationData;
  deposit: IDepositData;
}

interface IProps {
  costs: Partial<ICostsData>;
}

export const updateCostsData = ({ costs }: IProps) => {
  const filterZeroAmountPositions = (positions: IPosition[]) =>
    positions.filter((position) => parseFloat(position.amount) !== 0);

  const updatedCosts: Partial<ICostsData> = {};

  if (costs.compensation) {
    const filteredCompensationPositions = filterZeroAmountPositions(
      costs.compensation.positions
    );
    if (filteredCompensationPositions.length > 0) {
      updatedCosts.compensation = {
        ...costs.compensation,
        positions: filteredCompensationPositions,
      };
    }
  }

  if (costs.deposit) {
    const filteredDepositPositions = filterZeroAmountPositions(
      costs.deposit.positions
    );
    if (filteredDepositPositions.length > 0) {
      updatedCosts.deposit = {
        ...costs.deposit,
        positions: filteredDepositPositions,
      };
    }
  }

  return Object.keys(updatedCosts).length > 0 ? updatedCosts : undefined;
};
