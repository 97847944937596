import React from 'react';
import { Divider, Space } from 'antd';
import Paragraph from 'antd/es/typography/Paragraph';

import styles from '~/domain/contract/components/EditForm/EditFormGroup/EditFormGroup.module.scss';

interface IEditFormGroupProps {
  title: string;
}

export const EditFormGroup: React.FC<
  IEditFormGroupProps & React.PropsWithChildren
> = ({ title, children }) => {
  return (
    <Space direction="vertical" size="small" className={styles.space}>
      <Paragraph className={styles.paragraph}>{`# ${title}`}</Paragraph>
      <Divider className={styles.divider} />
      {children}
    </Space>
  );
};
