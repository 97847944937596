export const QUERY_KEYS = {
  USER: {
    WHOAMI: 'whoami',
  },
  AUTH: {
    LOGIN: 'login',
    LOGOUT: 'logout',
  },
  KYC: {
    DOCUMENTS: 'kycDocuments',
    APPROVE_DOCUMENT: 'kycApproveDocument',
    REJECT_DOCUMENT: 'kycRejectDocument',
  },
  EMPLOYEES: {
    LIST: 'employeesList',
    DOCUMENTS: 'employeeDocuments',
    DETAILS: 'employeeDetails',
    ORGANIZATIONS: 'employeeOrganizations',
  },
  EMPLOYER: {
    LIST: 'employersList',
  },
  COUNTRIES: 'countries',
  INVOICES: {
    LIST: 'invoicesList',
    INVOICE: 'invoice',
    NEXT: 'nextInvoice',
  },
  RECEIPTS: {
    LIST: 'receiptsList',
    RECEIPT: 'receipt',
    PAYROLL_DETAILS: 'payrollDetails',
    RECEIPT_DETAILS: 'receiptDetails',
  },
  ORGANIZATIONS: {
    LIST: 'organizationsList',
    DETAILS: 'organizationDetails',
    CREATE: 'organizationCreate',
    USERS: 'organizationUsers',
    USER_DETAIL: 'organizationUserDetail',
  },
  COMPLIANCE: {
    LIST: 'complianceList',
    BY_COUNTRY: 'complianceByCountry',
    COUNTRY_SET: 'complianceCountrySet',
  },
  RECRUITMENT: {
    LIST: 'recruitmentList',
    DETAILS: 'recruitmentDetails',
  },
  TALENTPOOL: {
    LIST: 'talentPoolList',
    DETAILS: 'talentPoolDetails',
  },
  RECOMMENDATION: {
    LIST: 'recommendationList',
  },
  CONTRACTS: {
    LIST: 'contractsList',
    EOR: {
      DETAILS: 'eorContractDetails',
    },
    FR: {
      DETAILS: 'frContractDetails',
    },
    PAYGO: {
      DETAILS: 'payGoDetails',
    },
    TIMESHEETS: {
      LIST: 'timesheetsList',
    },
  },
  PRICING: {
    LIST: 'pricingList',
    SAVE: 'pricingSave',
    PROFIT: 'profitList',
  },
  VISA: {
    LIST: 'visaList',
    DETAILS: 'visaDetails',
    DOCUMENTS_LIST: 'visaDocumentsList',
    APPROVE_DOCUMENT: 'visaApproveDocument',
    REJECT_DOCUMENT: 'visaRejectDocument',
  },
};
