import { Select, Space } from 'antd';
import { useTranslation } from 'react-i18next';

import { INIT_FILTERS_INVOICES } from '~/domain/invoices/hooks/useInvoiceList';
import { invoiceStatuses } from '~/domain/invoices/types/invoiceStatuses';
import { invoiceTypeArr } from '~/domain/invoices/types/invoiceType';
import { InputSearch } from '~/domain/shared/components/InputSearch';
import { useListFilter } from '~/domain/shared/hooks/useListFilter';

export const InvoiceFilters: React.FC = () => {
  const { t } = useTranslation(['invoice']);

  const { filterParams, handleSearchParams } = useListFilter(
    INIT_FILTERS_INVOICES
  );

  return (
    <Space className="mb-10">
      <div>
        <div className="font-medium">{t('invoice:invoiceNo')}</div>
        <InputSearch
          placeholder={t('invoice:invoiceNo')}
          onSearch={(val) => handleSearchParams('invoiceNumber', val)}
          initialValue={filterParams.invoiceNumber}
        />
      </div>
      <div>
        <div className="font-medium">{t('invoice:contractName')}</div>
        <InputSearch
          placeholder={t('invoice:contractName')}
          onSearch={(val) => handleSearchParams('contractName', val)}
          initialValue={filterParams.contractName}
        />
      </div>
      <div>
        <div className="font-medium">{t('invoice:status')}</div>
        <Select
          placeholder={t('invoice:status')}
          allowClear
          showSearch
          className="w-[200px]"
          onChange={(val) => handleSearchParams('status', val)}
          options={invoiceStatuses.map((status) => ({
            value: status,
            label: status,
          }))}
          value={filterParams.status}
        />
      </div>
      <div>
        <div className="font-medium">{t('invoice:type')}</div>
        <Select
          placeholder={t('invoice:type')}
          allowClear
          showSearch
          className="w-[200px]"
          onChange={(val) => handleSearchParams('type', val)}
          options={invoiceTypeArr.map((type) => ({
            value: type,
            label: type,
          }))}
          value={filterParams.type}
        />
      </div>
    </Space>
  );
};
