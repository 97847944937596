import axios from 'axios';

import { ENDPOINTS } from '~/domain/shared/utils/endpoints';
import { type IWhoAmIDTO } from '~/domain/user/api/dto/whoAmIDTO';
import { whoAmIModel } from '~/domain/user/api/model/whoAmIModel';

export const getWhoAmI = async () => {
  const { data } = await axios.get<IWhoAmIDTO>(ENDPOINTS.USER.WHOAMI);

  return whoAmIModel(data);
};
