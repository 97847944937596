import type { IFRContractDTO } from '~/domain/contract/api/dto/FRContractDTO';

export type TFRContractModel = ReturnType<typeof FRContractModel>;

export const FRContractModel = (data: IFRContractDTO) => ({
  id: data.id,
  createdAt: data.createdAt,
  updatedAt: data.updatedAt,
  cancel: {
    amount: data.cancel?.amount || '',
    date: data.cancel?.date || '',
  },
  invoice: {
    amount: data.invoice?.amount || '',
    dueDays: data.invoice?.dueDays || '',
    currency: data.invoice?.currency || '',
    endDate: data.invoice?.endDate || '',
  },
  general: {
    entity: data.general.entity,
    name: data.general.name,
    taxResidence: data.general.taxResidence,
    jobTitle: data.general.jobTitle,
    seniority: data.general.seniority,
    startDate: data.general.startDate,
    scope: data.general.scope,
    contractorName: data.general.contractorName,
    contractorEmail: data.general.contractorEmail,
    predefined: data.general.predefined || '',
  },
  dates: {
    firstPayment: {
      date: data.dates.firstPayment.date,
      type: data.dates.firstPayment.type,
      customAmount: data.dates.firstPayment.customAmount || undefined,
    },
    noticePeriodInDays: data.dates.noticePeriodInDays,
    finalPayment: {
      date: data.dates.finalPayment.date,
      type: data.dates.finalPayment.type,
      customAmount: data.dates.finalPayment.customAmount || undefined,
    },
  },
  payment: {
    currency: data.payment.currency,
    rate: data.payment.rate,
    frequency: data.payment.frequency,
    cycleEnds: data.payment.cycleEnds,
    paymentDue: data.payment.paymentDue,
    customPaymentDue: data.payment.customPaymentDue,
    semimonthlyType: data.payment.semimonthlyType,
    payWeekendAhead: data.payment.payWeekendAhead,
    monthlyType: data.payment.monthlyType,
  },
  compliance: {
    useIRContract: data.compliance.useIRContract,
    pdf: data.compliance.pdf || undefined,
  },
  organization: {
    id: data.organization?.id,
    orgId: data.organization?.orgId,
    name: data.organization?.name,
    legalName: data.organization?.legalName,
    address: data.organization?.address,
    type: data.organization?.type,
    registrationNumber: data.organization?.registrationNumber,
    registrationCertificate: data.organization?.registrationCertificate,
    signatoryName: data.organization?.signatoryName,
    signatoryEmail: data.organization?.signatoryEmail,
    logoPath: data.organization?.logoPath,
    vatId: data.organization?.vatId,
    size: data.organization?.size,
  },
  type: data.type,
  status: data.status,
  daysLeft: data.daysLeft,
});
