import { formatCurrency } from '~/domain/shared/utils/formatCurrency';

export const countTotalCosts: (
  data: {
    name: string;
    amount: string;
  }[],
  currencyCode: string
) => string = (data, currencyCode) => {
  if (!Array.isArray(data)) return 'Incorrect value';
  const sum =
    data.length > 0
      ? data.reduce((partialSum, item) => partialSum + Number(item.amount), 0)
      : 0;
  if (isNaN(sum) || !isFinite(sum)) return 'Incorrect value';
  return formatCurrency(sum, currencyCode);
};
