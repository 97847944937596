import { useMutation, useQueryClient } from '@tanstack/react-query';

import { getInvoiceCreateNext } from '~/domain/invoices/api/requests/getInvoiceCreateNext';
import { QUERY_KEYS } from '~/domain/shared/utils/queryKeys';

export const useCreateNextInvoice = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: getInvoiceCreateNext,
    onSuccess: () => {
      queryClient.resetQueries({ queryKey: [QUERY_KEYS.INVOICES.NEXT] });
    },
  });
};
