import { useQuery, useQueryClient, useMutation } from '@tanstack/react-query';

import {
  getContractsList,
  getEORContractDetails,
  getFRContractDetails,
  depositPaid,
  offlineMSA,
  setEORContractCosts,
  sendContractToEmployee,
  updateEORContractDetails,
  getPayGoContractDetails,
  getTimesheetList,
} from '~/domain/contract/api';
import { QUERY_KEYS } from '~/domain/shared/utils/queryKeys';
import type { IDepositPaidParams } from '~/domain/contract/types/eor/depositPaidParams';
import type { IContractsSearchParams } from '~/domain/contract/types/contractsSearchParams';
import { useListFilter } from '~/domain/shared/hooks/useListFilter';

export const useContractsList = (params: IContractsSearchParams) => {
  const { data, isLoading, isError } = useQuery({
    queryKey: [QUERY_KEYS.CONTRACTS.LIST, params],
    queryFn: () => getContractsList(params),
  });

  return {
    contracts: data,
    isLoading,
    isError,
  };
};

export const useEORContractDetails = (id: string | undefined) => {
  const { data, isLoading, isError, isSuccess } = useQuery({
    queryKey: [`${QUERY_KEYS.CONTRACTS.EOR.DETAILS}/${id}`],
    queryFn: () => getEORContractDetails(id),
    enabled: !!id,
    retry: false,
  });

  return { contract: data, isLoading, isError, isSuccess };
};

export const useFRContractDetails = (id: string | undefined) => {
  const { data, isLoading, isError } = useQuery({
    queryKey: [QUERY_KEYS.CONTRACTS.FR.DETAILS, id],
    queryFn: () => getFRContractDetails(id),
    enabled: !!id,
  });

  return { contract: data, isLoading, isError };
};

export const usePayGoDetails = (id: string | undefined) => {
  const { data, isPending, isError } = useQuery({
    queryKey: [QUERY_KEYS.CONTRACTS.PAYGO.DETAILS, id],
    queryFn: () => getPayGoContractDetails(id),
    enabled: !!id,
  });

  return { contract: data, isPending, isError };
};

export const useDepositPaid = (id: number, cb: () => void) => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ id, voucher }: IDepositPaidParams) =>
      await depositPaid(id, voucher),
    onSuccess: () => {
      queryClient.refetchQueries({
        queryKey: [QUERY_KEYS.CONTRACTS.EOR.DETAILS, id],
      });

      cb();
    },
  });
};

export const useUploadOfflineMSA = (id: number) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: offlineMSA,
    onSuccess: () => {
      queryClient.resetQueries({
        queryKey: [QUERY_KEYS.CONTRACTS.EOR.DETAILS, id],
      });
    },
  });
};

export const useEORContractCosts = (id: number, cb: () => void) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: setEORContractCosts,
    onSuccess: () => {
      queryClient.removeQueries({
        queryKey: [`${QUERY_KEYS.CONTRACTS.EOR.DETAILS}/${id}`],
      });
      cb?.();
    },
  });
};

export const useSendEORContractToEmployee = (id: number) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: sendContractToEmployee,
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.CONTRACTS.EOR.DETAILS, id],
      });
    },
  });
};

export const useUpdateEORContractDetails = (id: number, cb: () => void) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: updateEORContractDetails,
    onSuccess: () => {
      queryClient.removeQueries({
        queryKey: [QUERY_KEYS.CONTRACTS.EOR.DETAILS, id],
      });
      cb?.();
    },
  });
};

export const INIT_PAYGO_FILTERS = {
  page: 1,
  pageSize: 20,
  name: '',
  employer: '',
  status: undefined,
  sort: '',
  type: 'paygo' as const,
};

export const usePayGoList = () => {
  const { filterParams } = useListFilter(INIT_PAYGO_FILTERS);
  const { data, isPending, isError } = useQuery({
    queryKey: [QUERY_KEYS.EMPLOYER.LIST, filterParams],
    queryFn: () => getContractsList(filterParams),
  });

  return {
    contracts: data,
    isPending,
    isError,
  };
};

export const useTimesheetsList = (id: number) => {
  const { data, isPending, isError } = useQuery({
    queryKey: [QUERY_KEYS.CONTRACTS.TIMESHEETS.LIST, id],
    queryFn: () => getTimesheetList(id),
    enabled: !!id,
  });

  return { timesheets: data, isPending, isError };
};
