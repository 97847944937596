import React from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import { wrapUseRoutes } from '@sentry/react';

import { ProtectedRoute } from './Layout/ProtectedRoute';

import { Layout } from '~/Layout';
import { RouteSuspense } from '~/domain/shared/components/RouteSuspense';
import { lazyImport } from '~/domain/shared/components/LazyImport';
import { EditEORPage } from '~/pages/eorContract/EditEORPage';
import { PATH } from '~/domain/shared/utils/path';
import { InvoiceDetailsEditPage } from '~/pages/invoice/InvoiceDetailsEditPage';
import { InvoiceDetailsPage } from '~/pages/invoice/InvoiceDetailsPage';
import { InvoiceListPage } from '~/pages/invoice/InvoiceListPage';
import { CreateNextInvoice } from '~/domain/invoices/components/CreateNextInvoice';
import { UpdateEORPage } from '~/pages/eorContract/UpdateEORPage';

const { NotFound } = lazyImport(() => import('./pages/notFound'), 'NotFound');
const { Login } = lazyImport(() => import('./pages/login'), 'Login');
const { Organization } = lazyImport(
  () => import('./pages/organization'),
  'Organization'
);
const { OrganizationMemberListPage } = lazyImport(
  () => import('./pages/organization/OrganizationMemberListPage'),
  'OrganizationMemberListPage'
);
const { AddOrganizationPage } = lazyImport(
  () => import('./pages/organization/AddOrganizationPage'),
  'AddOrganizationPage'
);
const { EditOrganizationPage } = lazyImport(
  () => import('./pages/organization/EditOrganizationPage'),
  'EditOrganizationPage'
);
const { AddOrganizationMemberPage } = lazyImport(
  () => import('./pages/organization/AddOrganizationMemberPage'),
  'AddOrganizationMemberPage'
);

const { EditOrganizationMemberPage } = lazyImport(
  () => import('./pages/organization/EditOrganizationMemberPage'),
  'EditOrganizationMemberPage'
);
const { Employee } = lazyImport(() => import('./pages/employee'), 'Employee');
const { EmployeeDetailsPage } = lazyImport(
  () => import('./pages/employee/DetailsPage'),
  'EmployeeDetailsPage'
);

const { Employer } = lazyImport(() => import('./pages/employer'), 'Employer');

const { Receipt } = lazyImport(() => import('./pages/receipt'), 'Receipt');
const { ReceiptPayrollView } = lazyImport(
  () => import('./pages/receipt/ReceiptPayrollView'),
  'ReceiptPayrollView'
);
const { ReceiptDetailsView } = lazyImport(
  () => import('./pages/receipt/ReceiptDetailsView'),
  'ReceiptDetailsView'
);
const { EditReceiptView } = lazyImport(
  () => import('./pages/receipt/EditReceiptView'),
  'EditReceiptView'
);

const { FixedRateContract } = lazyImport(
  () => import('./pages/fixedRateContract'),
  'FixedRateContract'
);
const { FRContractDetailsPage } = lazyImport(
  () => import('./pages/fixedRateContract/DetailsPage'),
  'FRContractDetailsPage'
);
const { FRContractPDFPage } = lazyImport(
  () => import('./pages/fixedRateContract/ContractPDFPage'),
  'FRContractPDFPage'
);
const { EORContract } = lazyImport(
  () => import('./pages/eorContract'),
  'EORContract'
);
const { EORContractDetailsPage } = lazyImport(
  () => import('./pages/eorContract/DetailsPage'),
  'EORContractDetailsPage'
);

const { PayGoDetailsPage } = lazyImport(
  () => import('./pages/payGoContract/DetailsPage'),
  'PayGoDetailsPage'
);

const { PayGoContract } = lazyImport(
  () => import('./pages/payGoContract'),
  'PayGoContract'
);

const { PayGoContractPDFPage } = lazyImport(
  () => import('./pages/payGoContract/ContractPDFPage'),
  'PayGoContractPDFPage'
);

const { KYCListPage } = lazyImport(() => import('./pages/kyc'), 'KYCListPage');
const { KYCDetailsPage } = lazyImport(
  () => import('./pages/kyc/DetailsPage'),
  'KYCDetailsPage'
);

const { KYCSetCompliancePage } = lazyImport(
  () => import('./pages/kyc/SetCompliancePage'),
  'KYCSetCompliancePage'
);

const { Recruitment } = lazyImport(
  () => import('./pages/recruitment'),
  'Recruitment'
);

const { RecruitmentDetailsView } = lazyImport(
  () => import('./pages/recruitment/RecruitmentDetailsView'),
  'RecruitmentDetailsView'
);

const { TalentPool } = lazyImport(
  () => import('./pages/talentPool'),
  'TalentPool'
);

const { TalentPoolDetailsViewPage } = lazyImport(
  () => import('./pages/talentPool/TalentPoolDetailsViewPage'),
  'TalentPoolDetailsViewPage'
);

const { AddTalentPoolPage } = lazyImport(
  () => import('./pages/talentPool/AddTalentPoolPage'),
  'AddTalentPoolPage'
);

const { PricingManagement } = lazyImport(
  () => import('./pages/pricing'),
  'PricingManagement'
);

const { VisaService } = lazyImport(
  () => import('./pages/visaService/'),
  'VisaService'
);

const { VisaServiceDetailsViewPage } = lazyImport(
  () => import('./pages/visaService/VisaServiceDetailsViewPage'),
  'VisaServiceDetailsViewPage'
);

const router = [
  {
    path: PATH.LOG_IN,
    element: (
      <RouteSuspense>
        <Login />
      </RouteSuspense>
    ),
  },
  {
    path: '/',
    element: (
      <ProtectedRoute>
        <Layout />
      </ProtectedRoute>
    ),
    children: [
      {
        index: true,
        element: <Navigate to={PATH.EOR_CONTRACT} replace />,
      },
      //EOR CONTRACT
      {
        path: PATH.EOR_CONTRACT,
        children: [
          {
            index: true,
            element: (
              <RouteSuspense>
                <EORContract />
              </RouteSuspense>
            ),
          },
          {
            path: PATH.EOR_CONTRACT_DETAIL,
            element: (
              <RouteSuspense>
                <EORContractDetailsPage />
              </RouteSuspense>
            ),
          },
          {
            path: PATH.EOR_CONTRACT_DETAIL_EDIT,
            element: (
              <RouteSuspense>
                <EditEORPage />
              </RouteSuspense>
            ),
          },
          {
            path: PATH.EOR_CONTRACT_DETAIL_UPDATE,
            element: (
              <RouteSuspense>
                <UpdateEORPage />
              </RouteSuspense>
            ),
          },
        ],
      },
      // FIXED RATE CONTRACT
      {
        path: PATH.FIXED_RATE_CONTRACT,
        children: [
          {
            index: true,
            element: (
              <RouteSuspense>
                <FixedRateContract />
              </RouteSuspense>
            ),
          },
          {
            path: PATH.FIXED_RATE_CONTRACT_DETAIL,
            element: (
              <RouteSuspense>
                <FRContractDetailsPage />
              </RouteSuspense>
            ),
          },
          {
            path: PATH.FIXED_RATE_CONTRACT_VIEW,
            element: (
              <RouteSuspense>
                <FRContractPDFPage />
              </RouteSuspense>
            ),
          },
        ],
      },
      //PAY GO CONTRACT
      {
        path: PATH.PAY_GO_CONTRACT,
        children: [
          {
            index: true,
            element: (
              <RouteSuspense>
                <PayGoContract />
              </RouteSuspense>
            ),
          },
          {
            path: PATH.PAY_GO_CONTRACT_DETAIL,
            element: (
              <RouteSuspense>
                <PayGoDetailsPage />
              </RouteSuspense>
            ),
          },
          {
            path: PATH.PAY_GO_CONTRACT_VIEW,
            element: (
              <RouteSuspense>
                <PayGoContractPDFPage />
              </RouteSuspense>
            ),
          },
        ],
      },
      //ORGANIZATION MANAGEMENT
      {
        path: PATH.ORGANIZATION,
        children: [
          {
            index: true,
            element: (
              <RouteSuspense>
                <Organization />
              </RouteSuspense>
            ),
          },
          {
            path: PATH.ORGANIZATION_CREATE,
            element: (
              <RouteSuspense>
                <AddOrganizationPage />
              </RouteSuspense>
            ),
          },
          {
            path: PATH.ORGANIZATION_EDIT,
            element: (
              <RouteSuspense>
                <EditOrganizationPage />
              </RouteSuspense>
            ),
          },
          {
            path: PATH.ORGANIZATION_MEMBER,
            children: [
              {
                path: PATH.ORGANIZATION_ADD_MEMBER,
                element: (
                  <RouteSuspense>
                    <AddOrganizationMemberPage />
                  </RouteSuspense>
                ),
              },
              {
                path: PATH.ORGANIZATION_EDIT_MEMBER,
                element: (
                  <RouteSuspense>
                    <EditOrganizationMemberPage />
                  </RouteSuspense>
                ),
              },
            ],
          },
          {
            path: PATH.ORGANIZATION_MEMBERS_LIST,
            element: (
              <RouteSuspense>
                <OrganizationMemberListPage />
              </RouteSuspense>
            ),
          },
        ],
      },
      //EMPLOYEE
      {
        path: PATH.EMPLOYEE,
        children: [
          {
            index: true,
            element: (
              <RouteSuspense>
                <Employee />
              </RouteSuspense>
            ),
          },
          {
            path: PATH.EMPLOYEE_DETAIL,
            element: (
              <RouteSuspense>
                <EmployeeDetailsPage />
              </RouteSuspense>
            ),
          },
        ],
      },
      //EMPLOYER
      {
        path: PATH.EMPLOYER,
        children: [
          {
            index: true,
            element: (
              <RouteSuspense>
                <Employer />
              </RouteSuspense>
            ),
          },
        ],
      },
      //KYC
      {
        path: PATH.KYC_LIST,
        children: [
          {
            index: true,
            element: (
              <RouteSuspense>
                <KYCListPage />
              </RouteSuspense>
            ),
          },
          {
            path: PATH.KYC_DETAILS,
            element: (
              <RouteSuspense>
                <KYCDetailsPage />
              </RouteSuspense>
            ),
          },
          {
            path: PATH.KYC_COMPLIANCE_SET,
            element: (
              <RouteSuspense>
                <KYCSetCompliancePage />
              </RouteSuspense>
            ),
          },
        ],
      },
      //INVOICE
      {
        path: PATH.INVOICE,
        children: [
          {
            index: true,
            element: (
              <RouteSuspense>
                <InvoiceListPage />
              </RouteSuspense>
            ),
          },
          {
            path: PATH.INVOICE_DETAIL,
            element: (
              <RouteSuspense>
                <InvoiceDetailsPage />
              </RouteSuspense>
            ),
          },
          {
            path: PATH.INVOICE_DETAIL_EDIT,
            element: (
              <RouteSuspense>
                <InvoiceDetailsEditPage />
              </RouteSuspense>
            ),
          },
        ],
      },
      //RECEIPT
      {
        path: PATH.RECEIPT,
        children: [
          {
            index: true,
            element: (
              <RouteSuspense>
                <Receipt />
              </RouteSuspense>
            ),
          },
          {
            path: PATH.RECEIPT_PAYROLL,
            element: (
              <RouteSuspense>
                <ReceiptPayrollView />
              </RouteSuspense>
            ),
          },
          {
            path: PATH.RECEIPT_DETAIL,
            element: (
              <RouteSuspense>
                <ReceiptDetailsView />
              </RouteSuspense>
            ),
          },
          {
            path: PATH.RECEIPT_EDIT,
            element: (
              <RouteSuspense>
                <EditReceiptView />
              </RouteSuspense>
            ),
          },
        ],
      },
      {
        path: PATH.TEMP_CREATE_NEXT_INVOICE,
        element: (
          <RouteSuspense>
            <CreateNextInvoice />
          </RouteSuspense>
        ),
      },
      //RECRUITMENT
      {
        path: PATH.RECRUITMENT_LIST,
        children: [
          {
            index: true,
            element: (
              <RouteSuspense>
                <Recruitment />
              </RouteSuspense>
            ),
          },
          {
            path: PATH.RECRUITMENT_DETAILS,
            element: (
              <RouteSuspense>
                <RecruitmentDetailsView />
              </RouteSuspense>
            ),
          },
        ],
      },
      //TALENTPOOL
      {
        path: PATH.TALENT_POOL,
        children: [
          {
            index: true,
            element: (
              <RouteSuspense>
                <TalentPool />
              </RouteSuspense>
            ),
          },
          {
            path: PATH.TALENT_POOL_DETAILS,
            element: (
              <RouteSuspense>
                <TalentPoolDetailsViewPage />
              </RouteSuspense>
            ),
          },
          {
            path: PATH.TALENT_POOL_ADD,
            element: (
              <RouteSuspense>
                <AddTalentPoolPage />
              </RouteSuspense>
            ),
          },
        ],
      },
      //VISA
      {
        path: PATH.VISA_SERVICE,
        children: [
          {
            index: true,
            element: (
              <RouteSuspense>
                <VisaService />
              </RouteSuspense>
            ),
          },
          {
            path: PATH.VISA_SERVICE_DETAILS,
            element: (
              <RouteSuspense>
                <VisaServiceDetailsViewPage />
              </RouteSuspense>
            ),
          },
        ],
      },
      //PRICING_MANAGEMENT
      {
        path: PATH.PRICING_MANAGEMENT,
        element: (
          <RouteSuspense>
            <PricingManagement />
          </RouteSuspense>
        ),
      },
    ],
  },
  {
    path: '*',
    element: (
      <RouteSuspense>
        <NotFound />
      </RouteSuspense>
    ),
  },
];

const useSentryRoutes = wrapUseRoutes(useRoutes);
export const App: React.FC = () => {
  const element = useSentryRoutes(router);
  return element;
};
