import { type IWhoAmIDTO } from '~/domain/user/api/dto/whoAmIDTO';

export const whoAmIModel = (data: IWhoAmIDTO) => ({
  id: data.id,
  createdAt: data.createdAt,
  updatedAt: data.updatedAt,
  firstName: data.firstName,
  middleName: data.middleName || '',
  lastName: data.lastName,
  email: data.email,
  type: data.type,
  avatarPath: data.avatarPath,
});
