import React from 'react';
import { Dropdown, Avatar, type MenuProps } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import { useLogout } from '~/domain/user/hooks/useLogout';

export const HeaderAvatar: React.FC = () => {
  const { t } = useTranslation(['common']);
  const { mutate } = useLogout();

  const handleLogout = () => {
    mutate();
  };

  const items: MenuProps['items'] = [
    {
      label: <div onClick={handleLogout}>{t('logout')}</div>,
      key: 'logout',
    },
  ];

  return (
    <div className="mr-3">
      <Dropdown menu={{ items }} trigger={['click']} arrow={true}>
        <a onClick={(e) => e.preventDefault()}>
          <Avatar size={40} icon={<UserOutlined />} />
        </a>
      </Dropdown>
    </div>
  );
};
