import { Button, Input, Space } from 'antd';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useCreateNextInvoice } from '~/domain/invoices/hooks/useCreateNextInvoice';

export const CreateNextInvoice = () => {
  const [id, setId] = useState<number>(0);
  const { mutate } = useCreateNextInvoice();
  const { t } = useTranslation(['contract']);

  return (
    <Space direction="vertical" className="m-8 max-w-xs">
      <Input
        type="number"
        placeholder={t('contract:contractNumber')}
        onChange={(e) => setId(e.target.valueAsNumber)}
      />
      <Button
        disabled={!id || isNaN(id) || !isFinite(id)}
        type="primary"
        onClick={() => mutate(id)}
      >
        {t('contract:generateInvoice')}
      </Button>
      <p className="text-xs text-gray-500">
        {t('contract:generateInvoiceInfo')}
      </p>
    </Space>
  );
};
