import { useQuery } from '@tanstack/react-query';

import { getInvoiceList } from '~/domain/invoices/api/requests/getInvoiceList';
import { useListFilter } from '~/domain/shared/hooks/useListFilter';
import { QUERY_KEYS } from '~/domain/shared/utils/queryKeys';

export const INIT_FILTERS_INVOICES = {
  page: 1,
  pageSize: 20,
  invoiceNumber: '',
  contractName: '',
  status: undefined,
  type: undefined,
  sort: '-generationTime',
};

export const useInvoiceList = () => {
  const { filterParams } = useListFilter(INIT_FILTERS_INVOICES);

  const { data, isError, isLoading } = useQuery({
    queryKey: [QUERY_KEYS.INVOICES.LIST, filterParams],
    queryFn: () =>
      getInvoiceList({
        ...filterParams,
      }),
  });

  return {
    invoices: data,
    isLoading,
    isError,
  };
};
