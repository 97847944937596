import type { IContractsListDTO } from '~/domain/contract/api/dto/contractsListDTO';

export type TContractsListModel = ReturnType<typeof contractsListModel>;

export const contractsListModel = (data: IContractsListDTO) => ({
  totalRows: data.totalRows,
  totalPages: data.totalPages,
  data: data.data.map((contract) => ({
    id: contract.id,
    name: contract.name,
    employer: contract.employer,
    created: contract.created,
    status: contract.status,
  })),
});
