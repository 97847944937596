export const contractStatusEORArr = [
  'draft',
  'eornotestimated',
  'msaready',
  'eorunpaid',
  'eornotsent',
  'toapprove',
  'active',
  'overdue',
  'rejected',
  'archived',
  'cancelled',
  'expired',
] as const;

export const contractStatusFixedArr = [
  'draft',
  'ready',
  'toapprove',
  'active',
  'overdue',
  'rejected',
  'archived',
  'cancelled',
  'expired',
  'frnotinvited',
] as const;

export const contractStatusPayGoArr = [
  'ready',
  'toapprove',
  'overdue',
  'rejected',
  'archived',
  'cancelled',
  'expired',
  'frnotinvited',
  'active',
  'draft',
] as const;

export const allContractStatuses = [
  ...new Set([
    ...contractStatusEORArr,
    ...contractStatusFixedArr,
    ...contractStatusPayGoArr,
  ]),
] as const;

export type TContractStatusEOR = (typeof contractStatusEORArr)[number];
export type TContractStatusFixed = (typeof contractStatusFixedArr)[number];
export type TContractPayGo = (typeof contractStatusPayGoArr)[number];
export type TContractStatusAll = (typeof allContractStatuses)[number];
