import { type TAllSupportedCurrencies } from '~/domain/shared/types/currencies';
import { calculateCurrencyTotals } from '~/domain/shared/utils/calculateCurrencyTotals';

interface IProps {
  currency: TAllSupportedCurrencies;
  name: string;
  amount: string;
  vatRate: string;
  grossValue: string;
}

export const calculateCurrencyPositions = (positions: IProps[]) => {
  const formattedPositions = positions.map((item) => ({
    name: item.name,
    currency: item.currency,
    value: item.grossValue,
  }));

  return {
    totaledCurrencies: calculateCurrencyTotals(formattedPositions),
  };
};
