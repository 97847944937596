import { useMutation, useQueryClient } from '@tanstack/react-query';

import { setInvoiceAsApproved } from '~/domain/invoices/api/requests/setInvoiceAsApproved';
import { QUERY_KEYS } from '~/domain/shared/utils/queryKeys';

export const useInvoiceApprove = (id: number, cb?: () => void) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: setInvoiceAsApproved,
    onSuccess: () => {
      queryClient.resetQueries({
        queryKey: [QUERY_KEYS.INVOICES.INVOICE, 'new', id],
      });
      cb?.();
    },
  });
};
