import { Spin } from 'antd';
import React from 'react';
import { Navigate } from 'react-router-dom';

import { useWhoAmI } from '~/domain/user/hooks/useWhoAmI';

interface IProps {
  children: React.ReactElement;
}

export const ProtectedRoute: React.FC<IProps> = ({ children }) => {
  const { data, isLoading } = useWhoAmI();

  if (isLoading) {
    return (
      <div className="h-screen w-screen fixed left-0 bottom-0 right-0 top-0 flex justify-center items-center">
        <Spin size="large" />
      </div>
    );
  }

  if (!data) return <Navigate to="/login" />;
  return children;
};
