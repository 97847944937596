import { z } from 'zod';

import { contractStatusEORArr } from '~/domain/shared/types/contractStatus';
import type {
  TAllSupportedCurrencies,
  TContractCurrencies,
} from '~/domain/shared/types/currencies';

export const nameRegex = /^[a-zA-Z]+(?:[-][a-zA-Z]+)*$/;
export const optionalNameRegex = /^[\p{L}\p{M}'-]{0,50}$/u;
export const amountRegex = /^\d+(\.\d{1,2})?$/;
export const dateRegex = /^\d{4}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])$/;
export const requiredString = /.+/;

const integerSchema = z
  .union([z.number().int().positive(), z.string()])
  .transform((val) => {
    return Number(val);
  })
  .refine((val) => !isNaN(val));

const currencyCodeSchema = z.custom<TContractCurrencies>();
const paymentCurrencyCodeSchema = z.custom<TAllSupportedCurrencies>();

export const EORContractDetailsModelSchema = z
  .object({
    id: z.number().int(),
    createdAt: z.string(),
    updatedAt: z.string(),
    type: z.literal('eor'),
    status: z.enum(contractStatusEORArr),
    employee: z.object({
      firstName: z.string().regex(nameRegex),
      middleName: z.string().regex(optionalNameRegex).optional(),
      lastName: z.string().regex(nameRegex),
      email: z.string().email(),
      address: z.string().regex(requiredString),
      nationality: z.string().regex(requiredString),
      country: z.string().regex(requiredString),
      visaType: z.union([z.literal('own'), z.literal('support')]).optional(),
      visaFile: z.string().optional(),
      visa: z
        .object({
          status: z.string(),
          relocationCountry: z.string(),
          currentCountry: z.string(),
          citizenship: z.array(z.string()),
          education: z.string(),
          dependents: z.number(),
          linkedin: z.string().optional(),
          finalPayment: z.boolean(),
        })
        .optional(),
    }),
    job: z.object({
      entity: z.string().regex(requiredString),
      name: z.string().regex(requiredString),
      title: z.string().regex(requiredString),
      workplace: z.union([z.string(), z.undefined()]),
      seniority: z.string().regex(requiredString),
      scope: z.string().regex(requiredString),
    }),
    organization: z
      .object({
        id: z.number(),
        orgId: z.string(),
        name: z.string(),
        legalName: z.string(),
        address: z.string(),
        type: z.string(),
        registrationNumber: z.string().optional(),
        registrationCertificate: z.string().optional(),
        signatoryName: z.string(),
        signatoryEmail: z.string(),
        logoPath: z.string(),
        vatId: z.string(),
        size: z.string(),
      })
      .deepPartial()
      .default({}),
    compensation: z.object({
      currency: currencyCodeSchema,
      paymentCurrency: paymentCurrencyCodeSchema,
      feeCurrency: paymentCurrencyCodeSchema,
      salaryType: z.union([z.literal('monthly'), z.literal('annual')]),
      salary: z.string().regex(amountRegex),
      startDate: z.string().regex(dateRegex),
      employmentType: z.union([z.literal('fulltime'), z.literal('parttime')]),
      hoursPerWeek: z.number().positive().int(),
      timeOffType: z.union([z.literal('standard'), z.literal('specific')]),
      paidVacationDays: z.number().positive().int(),
      contractTermType: z.union([
        z.literal('indefinite'),
        z.literal('definite'),
      ]),
      endDate: z.union([z.string(), z.undefined()]),
      probationPeriod: integerSchema,
      bonuses: z
        .array(
          z.object({
            name: z.string().regex(requiredString),
            type: z.union([z.literal('flatrate'), z.literal('percentage')]),
            value: z.string().regex(amountRegex),
          })
        )
        .default([]),
    }),
    costs: z
      .object({
        compensation: z
          .object({
            currency: currencyCodeSchema,
            positions: z.array(
              z.object({
                name: z.string().regex(requiredString),
                amount: z.string().regex(amountRegex),
              })
            ),
          })
          .optional(),
        deposit: z
          .object({
            currency: currencyCodeSchema,
            positions: z.array(
              z.object({
                name: z.string().regex(requiredString),
                amount: z.string().regex(amountRegex),
              })
            ),
          })
          .optional(),
      })
      .optional(),
  })
  .superRefine((value, context) => {
    const { country } = value.employee;
    const { workplace } = value.job;
    const { endDate, contractTermType } = value.compensation;

    if (country === 'VN' && !workplace) {
      context.addIssue({
        code: z.ZodIssueCode.custom,
        message: `Workplace is required`,
        path: ['job', 'workplace'],
      });
    }

    if (contractTermType === 'definite' && !endDate) {
      context.addIssue({
        code: z.ZodIssueCode.custom,
        message: `End date is required`,
        path: ['compensation', 'endDate'],
      });
    }
  });

export type TEORContractDetailsModel = z.TypeOf<
  typeof EORContractDetailsModelSchema
>;

export const prepareEORContractDetailsModel = (data: unknown) => {
  return EORContractDetailsModelSchema.parse(data);
};
