import { countries } from '~/config/countries';

interface IContractCountries {
  code: (typeof countries)[number]['code'];
  EORContractFields: {
    hoursPerWeek: number;
    probationPeriod: number;
    noticePeriod: number;
    timeOff: number;
  };
}

export const contractCountriesData: IContractCountries[] = [
  {
    code: 'AU',
    EORContractFields: {
      hoursPerWeek: 38,
      probationPeriod: 90,
      noticePeriod: 30,
      timeOff: 38,
    },
  },
  {
    code: 'HK',
    EORContractFields: {
      hoursPerWeek: 35,
      probationPeriod: 90,
      noticePeriod: 30,
      timeOff: 7,
    },
  },
  {
    code: 'MY',
    EORContractFields: {
      hoursPerWeek: 48,
      probationPeriod: 90,
      noticePeriod: 30,
      timeOff: 22,
    },
  },
  {
    code: 'NZ',
    EORContractFields: {
      hoursPerWeek: 40,
      probationPeriod: 90,
      noticePeriod: 21,
      timeOff: 34,
    },
  },
  {
    code: 'IN',
    EORContractFields: {
      hoursPerWeek: 40,
      probationPeriod: 90,
      noticePeriod: 30,
      timeOff: 27,
    },
  },
  {
    code: 'ID',
    EORContractFields: {
      hoursPerWeek: 40,
      probationPeriod: 90,
      noticePeriod: 30,
      timeOff: 31,
    },
  },
  {
    code: 'PK',
    EORContractFields: {
      hoursPerWeek: 40,
      probationPeriod: 119,
      noticePeriod: 30,
      timeOff: 22,
    },
  },
  {
    code: 'PH',
    EORContractFields: {
      hoursPerWeek: 40,
      probationPeriod: 90,
      noticePeriod: 30,
      timeOff: 5,
    },
  },
  {
    code: 'VN',
    EORContractFields: {
      hoursPerWeek: 40,
      probationPeriod: 90,
      noticePeriod: 30,
      timeOff: 12,
    },
  },
  {
    code: 'TH',
    EORContractFields: {
      hoursPerWeek: 40,
      probationPeriod: 119,
      noticePeriod: 30,
      timeOff: 19,
    },
  },
  {
    code: 'SG',
    EORContractFields: {
      hoursPerWeek: 40,
      probationPeriod: 90,
      noticePeriod: 30,
      timeOff: 22,
    },
  },
];

export const contractCountries = contractCountriesData
  .map((contractCountry) => {
    const country = countries.find(
      (element) => element.code === contractCountry.code
    );

    if (country)
      return {
        ...contractCountry,
        name: country.name,
        currency: country.currency,
      };
  })
  .filter(Boolean);

export type TContractCountryCode = (typeof contractCountries)[number]['code'];
